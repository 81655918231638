:root {
  --mo-header-height: 4.2857rem;
}

@media screen and (max-width: 767.98px) {
  html {
    font-size: 3.61111vw;
  }

  #header {
    z-index: 88;
    background-color: #fff;
    padding-right: 1rem;
  }
  #header .left_wrap{
    display: block;
    width: 100%;
  }
  #header .left {
    background: #fff;
    width: 100%;
    height: var(--mo-header-height);
    justify-content: space-between;
  }

  #header .left .logo {
    max-width: 100%;
    display: block;
    width: 14.6154rem;
    height: 100%;
    background-image: url(../img/common/logo.png);
    background-size: 100% auto;
    flex-shrink: 0;
  }
  #nav {
    padding-top: var(--mo-header-height);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    display: none;
    z-index: 77;
  }

  .open-gnb #nav {
    display: flex;
  }

  .open-gnb{
    overflow: hidden;
  }
  #nav .user_ip {
    color: #fff;
  }
  #nav .user_ip span {
    display: block;
  }
  #nav .top_infor_wrap {
    padding: 2.1429rem 1.7857rem 1.7857rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  #nav .top_infor_wrap {
    display: block;
  }
  #nav .top_infor_wrap > div + div {
    display: block;
    margin-top: 0.7143rem;
    margin-left: 0;
  }
  #nav .user_ip span + span {
    margin-left: 0;
    margin-top: 0.7143rem;
  }

  #nav .sub_menu_wrap {
    height: calc(100% - 8.5714rem);
    overflow-y: auto;
  }
  #nav .dep_menu {
    background: #22345e;
    padding: 10px 0;
  }
  #nav .dep_menu li a {
    display: block;
    line-height: 40px;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    padding: 0 23px;
  }
  #nav .dep_menu li.active {
    position: relative;
  }
  #nav .dep_menu li.active::after {
    content: "";
    display: block;
    background: url(../img/common/sidebar-current-marker.svg) no-repeat center /
      7px auto;
    width: 6px;
    height: 15px;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 10;
    transform: translateY(-50%);
  }

  #nav .sub_menu {
    background-color: #22345e;
    /* padding: 0 30px; */
    overflow: hidden;
  }
  /* #nav  .sub_menu.on {
      background-color: #172850;
      
  } */
  #nav .sub_menu .label {
    font-size: 1.0714rem;
    line-height: 3.9286rem;
    padding-left: 3.9286rem;
    padding-right: 1.4286rem;
    background: no-repeat center left 1.4286rem;
  }
  #nav .sub_menu .label i {
    display: block;
    width: 1.4286rem;
    height: 1.4286rem;
  }
  #nav .sub_menu .sub_menu_item a.dep_2 {
    display: block;
    line-height: 3.5714rem;
    padding-left: 3.9286rem;
    font-size: 1rem;
  }



  /*  // 스토어 메뉴 // */

  #footer {
    padding-top: 50px;
  }
  #footer * {
    font-size: 14px;
    font-weight: 350;
  }
  #footer span + span {
    margin-left: 12px;
  }
  #footer p {
    margin-top: 6px;
  }

  /* filter */
  
.top_filter {
  flex-wrap: wrap;
}
.datepick_box{
  width: 100%;
}
.datepick_box span{
  width: 1.1538rem;
  flex-shrink: 0;
}
.in_box.search_box{
  width: 100%;
}
.top_filter input,
.top_filter select {
  padding-right: 1.875rem;
  height: 2.625rem;
  font-size: 0.9375rem;
  border-radius: 8px;
}
.top_filter input:focus,
.top_filter select:focus {
  border-color: var(--bs-deep-point);
}
.top_filter .sel_box{
  width: calc(50% - .5769rem);
}
.top_filter .sel_box+.sel_box{
  margin-left: 1.1538rem;
}
.form_item select option[value=""][disabled],
.top_filter select option[value=""][disabled] {
  display: none;
}
.top_filter .date_box input {
  background: no-repeat center right 0.625rem;
  background-image: url(../img/common/date_ico.png);
}
.top_filter .btn_box {
  margin: 0;
}
.top_filter div + .btn_box,
.top_filter div + div {
  margin-left: 0;
  margin-top: .7692rem;
}

.top_filter a,
.top_filter button {
  font-size: 0.9375rem;
  height: 2.625rem;
  line-height: 2.5rem;
}


  /* =============================================  //header, nav , footer_info // */
  #container {
    width: 100%;
    margin-top: var(--mo-header-height);
  }
  #container .bot_item table{
    min-width: unset;
    width: 100%;
    display: block;
  }
  #container .bot_item table {
    text-align: left;
    width: 100%;
    font-size: 0.9286rem;
    border-top-color: #252525;
  }
  #container .bot_item table td,
  #container .bot_item table table,
  #container .bot_item table tbody {
    display: block;
    width: 100%;
  }
  #container .bot_item table td.number_td{

    display: none;
  }
 
  #container .bot_item table td::before{
    display: inline-block;
  }
  #container .bot_item table td.cancel_td::before{
    content: '취소일자 : ';
  }
  #container .bot_item table td.created_date_td::before{
    content: '거래일자 : ';
  }
  #container .bot_item table td.sortation_td::before{
    content: '구분 : ';
  }
  #container .bot_item table td.getpaystatus_td::before{
    content: '정산 : ';
  }
  #container .bot_item table td.resultcode_td::before{
    content: 'ErrCode : ';
  }
  #container .bot_item table td.errormessage_td::before{
    content: 'Description : ';
  }
  #container .bot_item table td.ag_branch_name_td::before{
    content: '대리점 : ';
  }
  #container .bot_item table td.calculate_date_re_td::before{
    content: 'PG정산 : ';
  }
  #container .bot_item table td.st_branch_td::before{
    content: '가맹점 : ';
  }
  #container .bot_item table td.productname_td::before{
    content: '상품명 : ';
  }
  #container .bot_item table td.maskingFunc_td::before{
    content: '고객명 : ';
  }
  #container .bot_item table td.paymentTypeString_td::before{
    content: '결제방법 : ';
  }
  #container .bot_item table td.paymentTypeString_td2::before{
    content: '매체 : ';
  }
  #container .bot_item table td.pgcard_td::before{
    content: '매입 : ';
  }
  #container .bot_item table td.pgcard_td2::before{
    content: '발행 : ';
  }
  #container .bot_item table td.quota_td::before{
    content: '할부 : ';
  }
  #container .bot_item table td.amount_td::before{
    content: '거래금액 : ';
  }
 
  #container .bot_item table td.card_out_no_td::before{
    content: '카드번호 : ';
  }
  #container .bot_item table td.authno_td::before{
    content: '승인번호 : ';
  }
  #container .bot_item table td.orderno_td::before{
    content: '주문번호 : ';
  }
  #container .bot_item table td.cpid_td::before{
    content: '상점코드 : ';
  }
  #container .bot_item table td.pg_name_td::before{
    content: 'PG : ';
  }
  #container .bot_item table td.br_branch_name_td::before{
    content: '지사 : ';
  }
  #container .bot_item table td.di_branch_td::before{
    content: '총판 : ';
  }
  #container .bot_item table td.branch_name_td::before{
    content: '가맹점 : ';
  }
  #container .bot_item table td.goods_name_td::before{
    content: '상품명 : ';
  }
  #container .bot_item table td.price_td::before{
    content: '금액 : ';
  }
  #container .bot_item table td.branch_name_td::before{
    content: '가맹점 : ';
  }
  #container .bot_item table td.payEnd_txt_td::before{
    content: '상태 : ';
  }
  #container .bot_item table td.regdate2_td::before{
    content: '발행날짜 : ';
  }
  #container .bot_item table td.limitDay_td::before{
    content: '마감날짜 : ';
  }
  #container .bot_item table td.btn_wrap_td::before{
    content: '삭제 : ';
  }

  #container table .btn_wrap{
    display: inline-block;
    margin-left: 10px;
  }
  #container .bot_item table tr {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 0;
    border-bottom: 1px solid #dbdbdb;
  }

  #container .bot_item table thead {
    display: none;
  }

  #container .bot_item table tbody td {
    order: 2;
    text-align: left;
    border: none;
    padding: .3846rem;
  }
  #container .bot_item table tbody td.desc {
    padding-left: 0.7143rem;
    padding-top: 0.7143rem;
    padding-bottom: 0.7143rem;
  }
  #container .bot_item table tbody td.mo_top {
    order: 1;
    background-color: #fafafa;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #dbdbdb;
    padding: 1.0714rem 0.7143rem;
  }
  #container .bot_item table tbody td.how p,
  #container .bot_item table tbody td.date p {
    position: relative;
    color: #252525;
  }
  #container .bot_item table tbody td.how p::before,
  #container .bot_item table tbody td.date p::before {
    content: "일정 : ";
    display: inline-block;
    color: #757575;
  }
  #container .bot_item table tbody td.how p::before {
    content: "방법 : ";
  }
}
