@charset "utf-8";

.intro {
    display:flex;
    align-items: flex-start;
    /* max-width:1920px; */
    min-width:800px;
    width:100%;
    height:100vh;

    
}
.intro * {
    font-family:'Noto Sans KR','돋움','dotum', sans-serif;
}
.intro .visual_box {
    width:100%;
    height:100%;
}
.intro .intro_bg {
    display:block;
    width:100%; height:100%;

}
.intro.bg01 {
    background:url('../img/intro/visual01.jpg') center no-repeat;
    background-size:cover;
}
.intro.bg02 {
    background:url('../img/intro/visual02.jpg') center no-repeat;
    background-size:cover;
}
.intro.bg03 {
    background:url('../img/intro/visual03.jpg') center no-repeat;
    background-size:cover;
}
.intro .login_box {
    display:flex;
    align-items: center;
    justify-content: center;
    height:100%;
    width: 100%;
}
.intro .login_box form {
    width:100%;
    max-width:440px;
    background: #fff;
    padding: 80px 43px 60px;
    border-radius: 27px;
    box-sizing: border-box;
}
.intro .login_box .login_ttl {
    margin-bottom:30px;
    text-align: center;
}
.intro .login_box .login_ttl .logo {
    display:block;
    margin-bottom:20px;
}
.intro .login_box .login_ttl em {
    display:block;
    font-weight:400;
    color:#454545;
    font-size: 18px;
}
.intro .login_box .input_wrap input {
    width:100%;
    height:40px;
    border:1px solid rgb(219, 219, 219);
    font-size:14px;
    background-color: rgb(248, 248, 248);
}
.intro .login_box .input_wrap input::placeholder {
    color:#bdbdbd;
}
.intro .login_box .input_wrap + .input_wrap {
    margin-top:10px;
}
.intro .login_box .option_box {
    display:flex;
    align-items: center;
    justify-content: space-between;
    margin:15px 0;
}
.intro .login_box .option_box .check_box input {
    display: none;
} 
.intro .login_box .option_box .check_box label {
    font-size:16px;
    font-weight:350;
    padding-left: 25px;
    background: no-repeat center left ;
    background-image: url(../img/common/check.png);
}.intro .login_box .option_box .check_box input:checked+label {
    background-image: url(../img/common/check_on.png);
}
.intro .login_box .option_box .find_box {
    display:flex;
    align-items: center;
}
.intro .login_box .option_box .find_box a {
    font-size:16px;
    font-weight:350;
    color:#454545;
}
.intro .login_box .btn_wrap {
    display:flex;
    align-items: center;
    flex-direction: column;
    margin:30px 0 40px;
}
.intro .login_box .btn_wrap .login_btn {
    width:100%;
    max-width:360px;
    line-height:50px;
    font-size:18px;
    font-weight:500;
    color:#fff;
    text-align: center;
    background-color:#3276ae;
    border-radius:30px;
    box-shadow: 0px 8px 10px rgb(0 0 0 / 30%);
}
.intro .login_box .btn_wrap .direct_login {
    margin-top:20px;
    color:#757575;
}


.intro .login_box .add_info span {
    text-align: center;
    line-height: 1.5;
    display: block;
    font-weight: 400;
    font-size: 14px;
}

.intro .login_box .copyright {
    font-weight:350;
    color:#999;
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
}
