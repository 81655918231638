@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

*{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
body, html{
width: 100%;
height: 100%;
}
.preparing_sect{
	text-align: center;
	min-height: 100vh;
	padding: 50px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	font-family: 'NanumSquare', sans-serif;
}
.preparing_sect img{
	display: block;
	margin: 0 auto;
	max-width: 100%;
}
.preparing_sect h1{
	font-size: 48px;
	font-weight: 400;
	margin: 60px auto 22px;
	
}
.preparing_sect strong{font-weight: 800;}
.preparing_sect p{
	font-size: 24px;
	line-height: 1.5;
}