.flex--s,
.flex--e,
.flex--c,
.flex-e-s,
.flex-e-e,
.flex-e-c,
.flex-e,
.flex-c-s,
.flex-c-e,
.flex-c-c,
.flex-c,
.flex-a-s,
.flex-a-e,
.flex-a-c,
.flex-a,
.flex-b-s,
.flex-b-e,
.flex-b-c,
.flex-b,
.flex {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  display: -ms-flexbox;
}

.flex-b-s,
.flex-b-e,
.flex-b-c,
.flex-b {
  justify-content: space-between;
  -webkit-box-pack: space-between;
  -ms-flex-pack: space-between;
}

.flex-a-s,
.flex-a-e,
.flex-a-c,
.flex-a {
  justify-content: space-around;
  -webkit-box-pack: space-around;
  -ms-flex-pack: space-around;
}

.flex-c-s,
.flex-c-e,
.flex-c-c,
.flex-c {
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

.flex-e-s,
.flex-e-e,
.flex-e-c,
.flex-e {
  justify-content: end;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
}

.flex--s,
.flex-e-s,
.flex-c-s,
.flex-a-s,
.flex-b-s {
  align-items: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

.flex--c,
.flex-e-c,
.flex-c-c,
.flex-a-c,
.flex-b-c {
  align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.flex--e,
.flex-e-e,
.flex-c-e,
.flex-a-e,
.flex-b-e {
  align-items: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
}

.tal {
  text-align: left !important;
}

.tar {
  text-align: right !important;
}

.tac {
  text-align: center !important;
}

.color-w {
  color: var(--bs-white);
}

.color-b25 {
  color: var(--bs-dark);
}
.text-b45,
.color-b45 {
  color: var(--bs-dark2);
}

.color-g75 {
  color: var(--bs-gray);
}

.color-g99 {
  color: #999999 !important;
}

.color-point {
  color: var(--bs-point) !important;
}

.flex-w {
  flex-wrap: wrap;
}

.dr-row {
  flex-direction: row;
}

.dr-col {
  flex-direction: column;
}

.dr-col-reverse {
  flex-direction: column-reverse;
}

.dr-row-reverse {
  flex-direction: row-reverse;
}




[class*="flex"].col-2 {
  flex-wrap: wrap;
  width: -webkit-calc(100% + (var(--mg-x) * 2));
  /* for Chrome, Safari */
  width: -moz-calc(100% + (var(--mg-x) * 2));
  /* for Firefox */
  width: calc(100% + var(--mg-x) * 2);
  /* for IE */
  margin-left: -webkit-calc(-1 * var(--mg-x));
  margin-left: -moz-calc(-1 * var(--mg-x));
  margin-left: calc(-1 * var(--mg-x));
  margin-top: -webkit-calc(-1 * var(--mg-t));
  margin-top: -moz-calc(-1 * var(--mg-t));
  margin-top: calc(-1 * var(--mg-t));
}
[class*="flex"].col-2 > * {
  width: -webkit-calc(50% - (var(--mg-x) * 2));
  width: -moz-calc(50% - (var(--mg-x) * 2));
  width: calc(50% - var(--mg-x) * 2);
  margin: var(--mg-t) var(--mg-x) 0;
}

.flex1 > * {
  flex: 1;
}

[class*="flex"].col-3 {
  flex-wrap: wrap;
  width: -webkit-calc(100% + (var(--mg-x) * 2));
  width: -moz-calc(100% + (var(--mg-x) * 2));
  width: calc(100% + var(--mg-x) * 2);
  margin-left: -webkit-calc(-1 * var(--mg-x));
  margin-left: -moz-calc(-1 * var(--mg-x));
  margin-left: calc(-1 * var(--mg-x));
  margin-top: -webkit-calc(-1 * var(--mg-t));
  margin-top: -moz-calc(-1 * var(--mg-t));
  margin-top: calc(-1 * var(--mg-t));
}
[class*="flex"].col-3 > * {
  width: -webkit-calc(33.333% - (var(--mg-x) * 2));
  width: -moz-calc(33.333% - (var(--mg-x) * 2));
  width: calc(33.333% - var(--mg-x) * 2);
  margin: var(--mg-t) var(--mg-x) 0;
}

[class*="flex"].col-4 {
  flex-wrap: wrap;
  width: -webkit-calc(100% + (var(--mg-x) * 2));
  width: -moz-calc(100% + (var(--mg-x) * 2));
  width: calc(100% + var(--mg-x) * 2);
  margin-left: -webkit-calc(-1 * var(--mg-x));
  margin-left: -moz-calc(-1 * var(--mg-x));
  margin-left: calc(-1 * var(--mg-x));
  margin-top: -webkit-calc(-1 * var(--mg-t));
  margin-top: -moz-calc(-1 * var(--mg-t));
  margin-top: calc(-1 * var(--mg-t));
}
[class*="flex"].col-4 > * {
  width: -webkit-calc(25% - (var(--mg-x) * 2));
  width: -moz-calc(25% - (var(--mg-x) * 2));
  width: calc(25% - var(--mg-x) * 2);
  margin: var(--mg-t) var(--mg-x) 0;
}

[class*="flex"].col-5 {
  flex-wrap: wrap;
  width: -webkit-calc(100% + (var(--mg-x) * 2));
  width: -moz-calc(100% + (var(--mg-x) * 2));
  width: calc(100% + var(--mg-x) * 2);
  margin-left: -webkit-calc(-1 * var(--mg-x));
  margin-left: -moz-calc(-1 * var(--mg-x));
  margin-left: calc(-1 * var(--mg-x));
  margin-top: -webkit-calc(-1 * var(--mg-t));
  margin-top: -moz-calc(-1 * var(--mg-t));
  margin-top: calc(-1 * var(--mg-t));
}
[class*="flex"].col-5 > * {
  width: -webkit-calc(20% - (var(--mg-x) * 2));
  width: -moz-calc(20% - (var(--mg-x) * 2));
  width: calc(20% - var(--mg-x) * 2);
  margin: var(--mg-t) var(--mg-x) 0;
}

[class*="flex"].col-6 {
  flex-wrap: wrap;
  width: -webkit-calc(100% + (var(--mg-x) * 2));
  width: -moz-calc(100% + (var(--mg-x) * 2));
  width: calc(100% + var(--mg-x) * 2);
  margin-left: -webkit-calc(-1 * var(--mg-x));
  margin-left: -moz-calc(-1 * var(--mg-x));
  margin-left: calc(-1 * var(--mg-x));
  margin-top: -webkit-calc(-1 * var(--mg-t));
  margin-top: -moz-calc(-1 * var(--mg-t));
  margin-top: calc(-1 * var(--mg-t));
}
[class*="flex"].col-6 > * {
  width: -webkit-calc(16.66% - (var(--mg-x) * 2));
  width: -moz-calc(16.66% - (var(--mg-x) * 2));
  width: calc(16.66% - var(--mg-x) * 2);
  margin: var(--mg-t) var(--mg-x) 0;
}

[class*="flex"].equal-sizes > * {
  width: 50%;
}


[class*="flex"].w-auto-list {
  flex-wrap: wrap;
  width: -webkit-calc(100% + (var(--mg-x) * 2));
  /* for Chrome, Safari */
  width: -moz-calc(100% + (var(--mg-x) * 2));
  /* for Firefox */
  width: calc(100% + var(--mg-x) * 2);
  /* for IE */
  margin-left: -webkit-calc(-1 * var(--mg-x));
  margin-left: -moz-calc(-1 * var(--mg-x));
  margin-left: calc(-1 * var(--mg-x));
  margin-top: -webkit-calc(-1 * var(--mg-t));
  margin-top: -moz-calc(-1 * var(--mg-t));
  margin-top: calc(-1 * var(--mg-t));
}
[class*="flex"].w-auto-list > * {
  margin: var(--mg-t) var(--mg-x) 0;
}


[class*="flex"].side-by-side  {
  flex-wrap: nowrap;
}
[class*="flex"].side-by-side > * {
  margin-top: 0;
}
[class*="flex"].side-by-side > * + * {
  margin-left: var(--mg-s);
}

[class*="flex"].half > *{
  width: calc(50% - (var(--mg-s) / 2));
}
[class*="flex"].half > * + * {
  margin-left: var(--mg-s);
}

.bg-gray {
  background-color: #f8f8f8 !important;
}
.bg-gray100 {
  background-color: #eeeeee !important;
}

.bg-gray100-lighten {
  background-color: rgba(238, 238, 238, 0.25) !important;
}

.bg-gray200 {
  background-color: #dbdbdb !important;
}

.bg-gray200-lighten {
  background-color: rgba(219, 219, 219, 0.25) !important;
}

.bg-gray300 {
  background-color: #fafafa !important;
}

.bg-gray300-lighten {
  background-color: rgba(187, 187, 187, 0.25) !important;
}

.bg-gray400 {
  background-color: #999999 !important;
}

.bg-gray400-lighten {
  background-color: rgba(153, 153, 153, 0.25) !important;
}

.bg-gray500 {
  background-color: var(--bs-gray);
}

.bg-gray500-lighten {
  background-color: rgba(117, 117, 117, 0.25) !important;
}

.bg-gray600 {
  background-color: #666666 !important;
}

.bg-gray600-lighten {
  background-color: rgba(102, 102, 102, 0.25) !important;
}

.bg-gray700 {
  background-color: var(--bs-dark2);
}

.bg-gray700-lighten {
  background-color: rgba(69, 69, 69, 0.25) !important;
}

.bg-gray800 {
  background-color: #333333 !important;
}

.bg-gray800-lighten {
  background-color: rgba(51, 51, 51, 0.25) !important;
}

.bg-gray900 {
  background-color: var(--bs-dark);
}

.bg-gray900-lighten {
  background-color: rgba(37, 37, 37, 0.25) !important;
}

.bg-point {
  color: #fff;
  background-color: var(--bs-point) !important;
  border-color: var(--bs-point) !important;
}

.bg-point-lighten {
  background-color: var(--bs-point-lighten) !important;
}

.bg-point2 {
  color: #fff;
  background-color: var(--bs-point2) !important;
  border-color: var(--bs-point2) !important;
}

.bg-point2-lighten {
  background-color: var(--bs-bg-blue) !important;
  border-color: var(--bs-bg-blue) !important;
}

.bg-red {
  color: #fff;
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}

.bg-red-lighten {
  background-color: rgba(255, 0, 0, 0.25) !important;
  border-color: rgba(255, 0, 0, 0.25) !important;
}

.bg-blue {
  background-color: var(--bs-blue);
  border-color: var(--bs-blue);
}

.bg-blue-lighten {
  background-color: rgba(170, 222, 255, 0.25) !important;
  border-color: rgba(170, 222, 255, 0.25) !important;
}

.bg-yellow {
  background-color: var(--bs-yellow) !important;
  border-color: var(--bs-yellow) !important;
}

.bg-yellow-lighten {
  background-color: rgba(255, 255, 0, 0.25) !important;
  border-color: rgba(255, 255, 0, 0.25) !important;
}

.bg-white {
  background-color: var(--bs-white);
  border-color: var(--bs-white);
}

.bg-white-lighten {
  background-color: rgba(255, 255, 255, 0.25) !important;
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.bg-dark2 {
  background-color: var(--bs-dark2);
  border-color: var(--bs-dark2);
}

.bg-dark2-lighten {
  background-color: rgba(69, 69, 69, 0.25) !important;
  border-color: rgba(69, 69, 69, 0.25) !important;
}

.bg-dark {
  background-color: var(--bs-dark);
  border-color: var(--bs-dark);
}

.bg-dark-lighten {
  background-color: rgba(37, 37, 37, 0.25) !important;
  border-color: rgba(37, 37, 37, 0.25) !important;
}

.text-point {
  color: var(--bs-point) !important;
}

.text-deep-point {
  color: var(--bs-deep-point) !important;
}

.text-point2 {
  color: var(--bs-point2) !important;
}
.text-point3 {
  color: var(--bs-point3) !important;
}

.text-red {
  color: var(--bs-red);
}

.text-blue {
  color: var(--bs-blue);
}

.text-yellow {
  color: var(--bs-yellow) !important;
}
.text-green {
  color: var(--bs-green) !important;
}
.text-white {
  color: var(--bs-white);
}

.text-dark2 {
  color: var(--bs-dark2);
}

.text-bk {
  color: var(--bs-dark);
}
.text-gray2 {
  color: var(--bs-gray2);
}

.text-gray {
  color: var(--bs-gray);
}

.ellipsis-1 {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 말줄임 적용 */
}
.ellipsis-2 {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.4545;
  max-height: 2.91em;
}


input,
button,
select,
optgroup,
textarea {
  font-size: inherit;
  font-family: inherit;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  outline: 0;
}
input,
select,
textarea {
  border: 1px solid var(--bs-border-color);
  padding: var(--bs-input-padding);
  height: var(--bs-input-height);
  border-radius: var(--bs-input-rounded);
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--ct-card-bg);
  background-clip: border-box;
  border: 1px solid var(--ct-card-border-color);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1.5rem 1.5rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.5rem;
}

.card-header {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0;
  background-color: var(--ct-card-cap-bg);
  border-bottom: 1px solid var(--ct-card-border-color);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.5rem;
  background-color: var(--ct-card-cap-bg);
  border-top: 1px solid var(--ct-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.75rem;
  margin-bottom: -0.75rem;
  margin-left: -0.75rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--ct-card-bg);
  border-bottom-color: var(--ct-card-bg);
}

.card-header-pills {
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 12px;
}



.card {
  border: none;
  -webkit-box-shadow: var(--ct-card-box-shadow);
          box-shadow: var(--ct-card-box-shadow);
  margin-bottom: 24px;
}
.card .header-title {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  margin-top: 0;
}
.card .card-drop {
  font-size: 20px;
  color: inherit;
}
.card .card-widgets {
  float: right;
  height: 16px;
}
.card .card-widgets > a {
  color: inherit;
  font-size: 18px;
  display: inline-block;
  line-height: 1;
}
.card .card-widgets > a.collapsed i:before {
  content: "\f0415";
}

.card-title,
.card-header {
  margin-top: 0;
}

.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0.25rem;
  background: rgba(241, 243, 250, 0.8);
  cursor: progress;
}
.card-disabled .card-portlets-loader {
  background-color: #313a46;
  -webkit-animation: rotatebox 1.2s infinite ease-in-out;
          animation: rotatebox 1.2s infinite ease-in-out;
  height: 30px;
  width: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}



.bg-primary-lighten {
  background-color: rgba(114, 124, 245, 0.25) !important;
}

.bg-secondary-lighten {
  background-color: rgba(108, 117, 125, 0.25) !important;
}

.bg-success-lighten {
  background-color: rgba(10, 207, 151, 0.25) !important;
}

.bg-info-lighten {
  background-color: rgba(57, 175, 209, 0.25) !important;
}

.bg-warning-lighten {
  background-color: rgba(255, 188, 0, 0.25) !important;
}

.bg-danger-lighten {
  background-color: rgba(250, 92, 124, 0.25) !important;
}

.bg-light-lighten {
  background-color: rgba(238, 242, 247, 0.25) !important;
}

.bg-dark-lighten {
  background-color: rgba(49, 58, 70, 0.25) !important;
}

body .bg-primary {
  background-color: var(--bs-point) !important;
}
body .bg-purple {
  background-color: var(--bs-purple) !important;
}
body .bg-primary-lighten {
  background-color: rgba(114, 124, 245, 0.25) !important;
}
body .bg-secondary {
  background-color: var(--bs-point2) !important;
}
body .bg-secondary-lighten {
  background-color: rgba(206, 212, 218, 0.25) !important;
}
body .bg-success {
  background-color: #0acf97 !important;
}
body .bg-success-lighten {
  background-color: rgba(10, 207, 151, 0.25) !important;
}
body .bg-info {
  background-color: #39afd1 !important;
}
body .bg-info-lighten {
  background-color: rgba(57, 175, 209, 0.25) !important;
}
body .bg-warning {
  background-color: #ffbc00 !important;
}
body .bg-warning-lighten {
  background-color: rgba(255, 188, 0, 0.25) !important;
}
body .bg-danger {
  background-color: var(--bs-red) !important;
}
body .bg-danger-lighten {
  background-color: rgba(250, 92, 124, 0.25) !important;
}
body .bg-light {
  background-color: #464f5b !important;
}
body .bg-light-lighten {
  background-color: rgba(70, 79, 91, 0.25) !important;
}
body .bg-dark {
  background-color: #f1f1f1 !important;
}
body .bg-dark-lighten {
  background-color: rgba(241, 241, 241, 0.25) !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.text-body {
  color: #6c757d !important;
}

body .text-primary {
  color: var(--bs-point) !important;
}
body .text-secondary {
  color: var(--bs-point2) !important;
}
body .text-success {
  color: #0acf97 !important;
}
body .text-info {
  color: #39afd1 !important;
}
body .text-warning {
  color: #ffbc00 !important;
}
body .text-danger {
  color: var(--bs-red) !important;
}
body .text-light {
  color: #464f5b !important;
}
body .text-dark {
  color: #f1f1f1 !important;
}
body .text-body {
  color: #aab8c5 !important;
}

.border-dashed {
  border-style: dashed !important;
}

body .border-primary {
  border-color: var(--bs-point) !important;
}
body .border-secondary {
  border-color: var(--bs-point2) !important;
}
body .border-success {
  border-color: #0acf97 !important;
}
body .border-info {
  border-color: #39afd1 !important;
}
body .border-warning {
  border-color: #ffbc00 !important;
}
body .border-danger {
  border-color: var(--bs-red) !important;
}
body .border-light {
  border-color: #464f5b !important;
}
body .border-dark {
  border-color: #f1f1f1 !important;
}






.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.375rem !important;
  margin-left: 0.375rem !important;
}

.mx-2 {
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}

.mx-3 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-4 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important;
}

.mx-5 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.my-2 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.375rem !important;
}

.mt-2 {
  margin-top: 0.75rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2.25rem !important;
}

.mt-5 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.375rem !important;
}

.me-2 {
  margin-right: 0.75rem !important;
}

.me-3 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 2.25rem !important;
}

.me-5 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.375rem !important;
}

.mb-2 {
  margin-bottom: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2.25rem !important;
}

.mb-5 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.375rem !important;
}

.ms-2 {
  margin-left: 0.75rem !important;
}

.ms-3 {
  margin-left: 1.5rem !important;
}

.ms-4 {
  margin-left: 2.25rem !important;
}

.ms-5 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.375rem !important;
}

.m-n2 {
  margin: -0.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.m-n5 {
  margin: -4.5rem !important;
}

.mx-n1 {
  margin-right: -0.375rem !important;
  margin-left: -0.375rem !important;
}

.mx-n2 {
  margin-right: -0.75rem !important;
  margin-left: -0.75rem !important;
}

.mx-n3 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n4 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important;
}

.mx-n5 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important;
}

.my-n1 {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}

.my-n2 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n4 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n5 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.mt-n1 {
  margin-top: -0.375rem !important;
}

.mt-n2 {
  margin-top: -0.75rem !important;
}

.mt-n3 {
  margin-top: -1.5rem !important;
}

.mt-n4 {
  margin-top: -2.25rem !important;
}

.mt-n5 {
  margin-top: -4.5rem !important;
}

.me-n1 {
  margin-right: -0.375rem !important;
}

.me-n2 {
  margin-right: -0.75rem !important;
}

.me-n3 {
  margin-right: -1.5rem !important;
}

.me-n4 {
  margin-right: -2.25rem !important;
}

.me-n5 {
  margin-right: -4.5rem !important;
}

.mb-n1 {
  margin-bottom: -0.375rem !important;
}

.mb-n2 {
  margin-bottom: -0.75rem !important;
}

.mb-n3 {
  margin-bottom: -1.5rem !important;
}

.mb-n4 {
  margin-bottom: -2.25rem !important;
}

.mb-n5 {
  margin-bottom: -4.5rem !important;
}

.ms-n1 {
  margin-left: -0.375rem !important;
}

.ms-n2 {
  margin-left: -0.75rem !important;
}

.ms-n3 {
  margin-left: -1.5rem !important;
}

.ms-n4 {
  margin-left: -2.25rem !important;
}

.ms-n5 {
  margin-left: -4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.375rem !important;
  padding-left: 0.375rem !important;
}

.px-2 {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.px-3 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-4 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important;
}

.px-5 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.py-2 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-5 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.375rem !important;
}

.pt-2 {
  padding-top: 0.75rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2.25rem !important;
}

.pt-5 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.375rem !important;
}

.pe-2 {
  padding-right: 0.75rem !important;
}

.pe-3 {
  padding-right: 1.5rem !important;
}

.pe-4 {
  padding-right: 2.25rem !important;
}

.pe-5 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.375rem !important;
}

.pb-2 {
  padding-bottom: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2.25rem !important;
}

.pb-5 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.375rem !important;
}

.ps-2 {
  padding-left: 0.75rem !important;
}

.ps-3 {
  padding-left: 1.5rem !important;
}

.ps-4 {
  padding-left: 2.25rem !important;
}

.ps-5 {
  padding-left: 4.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.375rem !important;
}

.gap-2 {
  gap: 0.75rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2.25rem !important;
}

.gap-5 {
  gap: 4.5rem !important;
}


.d-inline-block {
  display: inline-block;
}
.d-block {
  display: block;
}
.d-none {
  display: none;
}
.d-flex {
  display: flex;
}
.d-inline-flex {
  display: inline-flex;
}

.border-none {
  border: none;
}
.border-top {
  border-top: 1px solid #dbdbdb;
}
.border-bottom {
  border-bottom: 1px solid #dbdbdb;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}
.mx-w-custom {
  max-width: var(--mx-w) !important;
}
.mb-30 {
  margin-bottom: 30px;
}
.only_web {
  display: block !important;
}

.only_mo {
  display: none !important;
}

.only_mo_inline {
  display: none !important;
}

.only_web_inline {
  display: inline-block !important;
}

.only_web_flex {
  display: flex !important;
}

small.web {
  display: inline-block !important;
}

small.mo {
  display: none !important;
}

small {
  width: 1px;
  height: 0.8em;
  display: inline-block;
  margin: -0.2em 0.5em 0 0.75em;
  vertical-align: middle;
}

.hidden {
  overflow: hidden;
}

.textarea_wrap {
  position: relative;
}

.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 32px 34px;
}

.placeholder p {
  line-height: 1.5;
  color: #999;
}

input[type="radio"],
input[type="checkbox"] {
  /* display: none; */
  opacity: 0.011;
  z-index: 100;
}

select,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"] {
  width: 100%;
  display: block;
  border: 1px solid #bbbbbb;
  /* border-radius: 6px; */
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"] {
  padding: 0 0.9375em;
}

textarea::placeholder {
  color: #bbbbbb;
}

/* input[type=text]:hover,
input[type=text]:focus,
input[type=password]:hover,
input[type=password]:focus,
input[type=email]:hover,
input[type=email]:focus,
input[type=number]:hover,
input[type=number]:focus,
input[type=tel]:hover,
input[type=tel]:focus {
  border-color: #eee;
} */
/* 
.btn-point {
  background-color: var(--bs-point) !important;
  border-color: var(--bs-point) !important;
  color: #fff !important;
} */
.btn-default {
  background-color: var(--bs-white);
  border-color: #aaaaaa !important;
  color: #252525;
}

.btn-deep-primary{
  background-color: var(--bs-deep-point) !important;
  border-color: var(--bs-deep-point) !important;
  color: #fff;
}
.btn-primary {
  background-color: var(--bs-point) !important;
  border-color: var(--bs-point) !important;
  color: #fff;
}

.btn-secondary {
  background-color: var(--bs-point2) !important;
  border-color: var(--bs-point2) !important;
  color: #fff;
}

.btn-third {
  background-color: var(--bs-point3) !important;
  border-color: var(--bs-point3) !important;
  color: var(--bs-white);
}

.btn-lemon {
  background-color: #e7f267;
  border-color: #e7f267;
  color: #fff;
}

.btn-purple {
  background-color: #54247d;
  border-color: #54247d;
  color: #fff;
}

.btn-tangerine {
  background-color: #ffb470;
  border-color: #ffb470;
  color: #fff;
}

.btn-orange {
  background-color: #fd7e14;
  border-color: #fd7e14;
  color: #fff;
}

.btn-darkBlue {
  background-color: #065f67;
  border-color: #065f67;
  color: #fff;
}

.btn-brown {
  background-color: #a56232;
  border-color: #a56232;
  color: #fff;
}

.btn-deepNight {
  background-color: #54247d;
  border-color: #54247d;
  color: #fff;
}

.btn-skyBlue {
  background-color: #a3dee2;
  border-color: #a3dee2;
  color: #fff;
}

.btn-darkGreen {
  background-color: #878d00;
  border-color: #878d00;
  color: #fff;
}

.btn-light {
  background-color: #eee !important;
  border-color: #eee !important;
  color: #656565 !important;
}

.btn-light2 {
  background-color: #dbdbdb !important;
  border-color: #dbdbdb !important;
  color: #999 !important;
}

.btn-gray {
  background-color: #999999 !important;
  border-color: #999999 !important;
  color: #fff !important;
}

.btn-gray2 {
  background-color: #757575;
  border-color: #757575;
  color: #fff;
}

.btn-dark {
  background-color: #252525;
  border-color: #252525;
  color: #fff;
}

.btn-outline-point {
  color: var(--bs-point);
  border-color: var(--bs-point);
}

.btn-outline-primary {
  color: var(--bs-point);
  border-color: var(--bs-point);
}

.btn-outline-secondary {
  color: var(--bs-point2);
  border-color: var(--bs-point2);
}

.btn-outline-lemon {
  color: #e7f267;
  border-color: #e7f267;
}

.btn-outline-purple {
  color: #54247d;
  border-color: #54247d;
}

.btn-outline-tangerine {
  color: #ffb470;
  border-color: #ffb470;
}

.btn-outline-orange {
  color: #fd7e14;
  border-color: #fd7e14;
}

.btn-outline-darkBlue {
  color: #065f67;
  border-color: #065f67;
}

.btn-outline-brown {
  color: #a56232;
  border-color: #a56232;
}

.btn-outline-deepNight {
  color: #54247d;
  border-color: #54247d;
}

.btn-outline-skyBlue {
  color: #a3dee2;
  border-color: #a3dee2;
}

.btn-outline-darkGreen {
  color: #878d00;
  border-color: #878d00;
}

.btn-outline-light {
  color: #dbdbdb;
  border-color: #eee;
}
.btn-outline-light2 {
  color: #999;
  border-color: #dbdbdb;
}
.btn-outline-gray {
  color: #999999;
  border-color: #999999;
}

.btn-outline-gray2 {
  color: #757575;
  border-color: #757575;
}

.btn-outline-dark {
  color: #252525;
  border-color: #252525;
}
.outline-point {
  border-color: var(--bs-point) !important;
}

.outline-primary {
  border-color: var(--bs-point) !important;
}

.outline-secondary {
  border-color: var(--bs-point2) !important;
}

.outline-lemon {
  border-color: #e7f267 !important;
}

.outline-purple {
  border-color: #54247d !important;
}

.outline-tangerine {
  border-color: #ffb470 !important;
}

.outline-orange {
  border-color: #fd7e14 !important;
}

.outline-darkBlue {
  border-color: #065f67 !important;
}

.outline-brown {
  border-color: #a56232 !important;
}

.outline-deepNight {
  border-color: #54247d !important;
}

.outline-skyBlue {
  border-color: #a3dee2 !important;
}

.outline-darkGreen {
  border-color: #878d00 !important;
}

.outline-light {
  border-color: #dbdbdb !important;
}

.outline-gray {
  border-color: #999999 !important;
}

.outline-gray2 {
  border-color: var(--bs-gray);
}

.outline-dark {
  border-color: var(--bs-dark);
}

.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all {
  display: none;
  position: relative;
  margin-top: 10px;
  background: #fff;
  border: solid 1px #dddddd;
  border-radius: 5px;
  z-index: 99999 !important;
}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all
  table {
  background: #fff;
}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all
  table
  thead
  tr
  th {
  width: 32px;
  line-height: 24px;
  height: 24px;
  text-align: center;
  padding-right: 5px;
}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all
  table
  thead
  tr
  th
  span {
  font-size: 10.1px;
  font-weight: 300;
  color: #999999;
}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all
  table
  tbody
  tr
  td {
  text-align: center;
  color: #666666;
  font-size: 12px;
  width: 35px;
  height: 35px;
  padding-right: 0px;
  background: #ffffff;
  margin: 1px;
}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all
  table
  tbody
  tr
  td
  a {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 33px;
  border-radius: 50%;
  font-weight: 300;
}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all
  table
  tbody
  tr
  td
  a:hover {
  background-color: var(--bs-point);
  color: #fff;
}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all
  table
  tbody
  tr
  td
  a.ui-state-default.ui-state-active {
  border: solid 1px var(--bs-point);
}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all
  table
  tbody
  tr
  td.ui-datepicker-unselectable.ui-state-disabled {
  background: #fff;
  color: #cecece;
}

.ui-datepicker-title {
  display: table;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
.ui-datepicker-title span {
  text-align: center;
  color: #000000;
  font-size: 15px;
  font-weight: 500;
}
.ui-datepicker-title span.ui-datepicker-year {
  font-weight: 300;
}
.ui-datepicker-title select.ui-datepicker-year,
.ui-datepicker-title select.ui-datepicker-month {
  display: inline-block;
  border: 1px solid #dbdbdb;
  width: 32px;
  height: 20px;
  box-sizing: content-box;
}
.ui-datepicker-title select.ui-datepicker-month {
  margin-left: 10px;
}

.ui-datepicker-header.ui-widget-header.ui-helper-clearfix.ui-corner-all {
  position: relative;
}
.ui-datepicker-header.ui-widget-header.ui-helper-clearfix.ui-corner-all
  a.ui-datepicker-prev {
  text-indent: -9999px;
  position: absolute;
  background: url("../img/btn/calandar_left.png") no-repeat center;
  width: 16px;
  height: 18px;
  left: 10px;
  top: -2px;
  cursor: pointer;
}
.ui-datepicker-header.ui-widget-header.ui-helper-clearfix.ui-corner-all
  a.ui-datepicker-next {
  text-indent: -9999px;
  position: absolute;
  background: url("../img/btn/calandar_right.png") no-repeat center;
  width: 16px;
  height: 18px;
  right: 10px;
  top: -2px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  small.web {
    display: none !important;
  }
  small.mo {
    display: inline-block !important;
  }
  .only_web {
    display: none !important;
  }
  .only_mo {
    display: block !important;
  }
  .only_mo_inline {
    display: inline-block !important;
  }
  .only_web_inline {
    display: none !important;
  }
  .only_web_flex {
    display: none !important;
  }
  
  [class*="flex"].mo-col-1 {
    width: 100%;
    margin-left: 0;
    margin-top: -webkit-calc(-1 * var(--mo-mg-t));
    margin-top: -moz-calc(-1 * var(--mo-mg-t));
    margin-top: calc(-1 * var(--mo-mg-t));
  }
  [class*="flex"].mo-col-1 > * {
    width: 100% !important;
    margin: var(--mo-mg-t) 0 0;
  }

  [class*="flex"].mo-col-2 {
    flex-wrap: wrap;
    width: -webkit-calc(100% + (var(--mo-mg-x) * 2));
    /* for Chrome, Safari */
    width: -moz-calc(100% + (var(--mo-mg-x) * 2));
    /* for Firefox */
    width: calc(100% + var(--mo-mg-x) * 2);
    /* for IE */
    margin-left: -webkit-calc(-1 * var(--mo-mg-x));
    margin-left: -moz-calc(-1 * var(--mo-mg-x));
    margin-left: calc(-1 * var(--mo-mg-x));
    margin-top: -webkit-calc(-1 * var(--mo-mg-t));
    margin-top: -moz-calc(-1 * var(--mo-mg-t));
    margin-top: calc(-1 * var(--mo-mg-t));
  }
  [class*="flex"].mo-col-2 > * {
    width: -webkit-calc(50% - (var(--mo-mg-x) * 2));
    width: -moz-calc(50% - (var(--mo-mg-x) * 2));
    width: calc(50% - var(--mo-mg-x) * 2);
    margin: var(--mo-mg-t) var(--mo-mg-x) 0;
  }

   .mo-block-100 {
    display: block !important;
  }
  .mo-block-100 > * {
    flex: unset;
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 0;
  }

  [class*="col"].mo-block-100 {
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 0;
  }
 
  [class*="col"].mo-block-100 > * {
    flex: unset;
    width: 100% !important;
    margin: var(--mo-mg-t) 0 0 ;
  }
 
  .mo-w-10 {
    width: 10% !important;
  }

  .mo-w-20 {
    width: 20% !important;
  }

  .mo-w-30 {
    width: 30% !important;
  }

  .mo-w-40 {
    width: 40% !important;
  }

  .mo-w-50 {
    width: 50% !important;
  }

  .mo-w-60 {
    width: 60% !important;
  }

  .mo-w-70 {
    width: 70% !important;
  }

  .mo-w-80 {
    width: 80% !important;
  }

  .mo-w-90 {
    width: 90% !important;
  }

  .mo-w-100 {
    width: 100% !important;
  }

  .mo-w-auto {
    width: auto !important;
  }
}
