@charset "utf-8";
:root {
  --nav-width: 260px;
  --header-height: 90px;
}

html {
  /* min-width: 1800px; */
  overflow: auto;
}
html.mo {
  min-width: inherit;
}

#wrap {
  min-height: 100vh;
  display: flex;
  width: 100%;
  position: relative;
}
#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 20;
}
#header .left_wrap {
  display: flex;
  align-items: center;
}

#header .left {
  background: #22345e;
  width: var(--nav-width);
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}
#header .left .logo {
  display: flex;
  max-width: 12.5rem;
  width: 260px;
  height: 24px;
  background: url(../img/common/logo_02.png) no-repeat center / cover;
}
#header .left .logo a {
  display: block;
  width: 100%;
  height: 100%;
}
.header_btns_wrap {
  padding-left: 0.9375rem;
}

.top_infor_wrap > div + div {
  margin-left: 1.25rem;
}
#header .right {
  display: flex;
  width: calc(100% - var(--nav-width));
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.9375rem;
  border-bottom: 1px solid #eeeeee;
  height: var(--header-height);
}
#header .right .page_ttl {
  font-size: 1.125rem;
  font-weight: 700;
}

.user_ip {
  font-size: 0.9375rem;
}
.user_ip span + span {
  margin-left: 1.5rem;
}

#nav {
  padding-top: 80px;
  width: var(--nav-width);
  background: #22345e;
}

#nav .dep_menu {
  background: #22345e;
  padding: 0.625rem 0;
}
#nav .dep_menu li a {
  display: block;
  line-height: 2.5rem;
  color: #fff;
  font-weight: 700;
  font-size: 0.9375rem;
  padding: 0 23px;
}
#nav .dep_menu li.active {
  position: relative;
}
#nav .dep_menu li.active::after {
  content: "";
  display: block;
  background: url(../img/common/sidebar-current-marker.svg) no-repeat center /
    7px auto;
  width: 6px;
  height: 0.9375rem;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 10;
  transform: translateY(-50%);
}

#nav .sub_menu {
  background-color: #22345e;
  /* padding: 0 1.875rem; */
  overflow: hidden;
}
/* #nav  .sub_menu.on {
    background-color: #172850;
    
} */
#nav .sub_menu .label {
  font-size: 0.9375rem;
  line-height: 3.75rem;
  padding-left: 3.75rem;
  padding-right: 1.875rem;
  color: #a0a9b5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  background: no-repeat center left 1.875rem;
  transition: all 0.3s;
}
#nav .sub_menu .label i {
  display: block;
  width: 1rem;
  height: 1rem;
  background: no-repeat center / 50% auto;
  background-image: url(../img/common/arrow_icon.png);

  transition: all 0.3s;
}
#nav .sub_menu.on .label {
  color: var(--bs-point);
}
#nav .sub_menu.on .label i {
  transform: rotate(180deg);
}

#nav .sub_menu.menu_8 .label {
  background-image: url(../img/common/menu_ico_01.png);
}
#nav .on .sub_menu.menu_8 .label,
#nav .sub_menu.on.menu_8 .label {
  background-image: url(../img/common/menu_ico_01_on.png);
}
#nav .sub_menu.menu_0 .label {
  background-image: url(../img/common/menu_ico_03.png);
}
#nav .sub_menu.menu_1 .label {
  background-image: url(../img/common/menu_ico_03.png);
}
#nav .on .sub_menu.menu_1 .label,
#nav .sub_menu.on.menu_1 .label {
  background-image: url(../img/common/menu_ico_03_on.png);
}

#nav .sub_menu.menu_2 .label {
  background-image: url(../img/common/menu_ico_04.png);
}
#nav .on .sub_menu.menu_2 .label,
#nav .sub_menu.on.menu_2 .label {
  background-image: url(../img/common/menu_ico_04_on.png);
}

#nav .sub_menu.menu_3 .label {
  background-image: url(../img/common/menu_ico_05.png);
}
#nav .on .sub_menu.menu_3 .label,
#nav .sub_menu.on.menu_3 .label {
  background-image: url(../img/common/menu_ico_05_on.png);
}

#nav .sub_menu.menu_5 .label {
  background-image: url(../img/common/menu_ico_07.png);
}
#nav .on .sub_menu.menu_5 .label,
#nav .sub_menu.on.menu_5 .label {
  background-image: url(../img/common/menu_ico_07_on.png);
}

#nav .sub_menu.menu_6 .label {
  background-image: url(../img/common/menu_ico_08.png);
}
#nav .on .sub_menu.menu_6 .label,
#nav .sub_menu.on.menu_6 .label {
  background-image: url(../img/common/menu_ico_08_on.png);
}

#nav .sub_menu.menu_7 .label {
  background-image: url(../img/common/menu_ico_09.png);
}
#nav .on .sub_menu.menu_7 .label,
#nav .sub_menu.on.menu_7 .label {
  background-image: url(../img/common/menu_ico_09_on.png);
}

#nav .sub_menu .sub_menu_item {
  display: none;
}
#nav .sub_menu .sub_menu_item.open {
  display: block;
}
/* #nav  .sub_menu .sub_menu_item a.dep_2 {
    color: #a1cfe6;
    font-size: 0.9375rem;
    line-height: 1.875rem;
    display: block;

} */
#nav .sub_menu .sub_menu_item a.dep_2 {
  display: block;
  line-height: 2.5rem;
  padding-left: 3.75rem;
  position: relative;
  color: #a0a9b5;
  font-size: 0.9375rem;
}

#nav .sub_menu .sub_menu_item li.on a,
#nav .sub_menu .sub_menu_item a:hover {
  color: #fff;
  background: #172850;
}
#nav .gnb li.on a::after,
#nav .sub_menu .sub_menu_item li.on a::after,
#nav .sub_menu .sub_menu_item a:hover::after {
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  background: var(--bs-point);
  position: absolute;
  top: 0;
  left: 0;
}

/* 스토어 메뉴 */
#nav .gnb {
  background: #3276ae;
}

#nav .gnb li.on a {
  color: var(--bs-point);
  background-color: #172850;
  position: relative;
}

/*  // 스토어 메뉴 // */

#footer {
  padding-top: 3.125rem;
}
#footer * {
  font-size: 0.875rem;
  font-weight: 350;
}
#footer span + span {
  margin-left: 0.75rem;
}
#footer p {
  margin-top: 6px;
}

/* =============================================  //header, nav , footer_info // */

.page_ttl {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.875rem;
}
/* -- main */
.main .main_cont {
  margin-bottom: 1.25rem;
}
.main .main_cont:last-child {
  margin: 0;
}
.main .payment_info_sec {
  display: flex;
}
/* .main .w_80 {
  width: calc(100% - 430px);
  margin-right: 1.25rem;
}
.main .w_20 {
  width: 410px;
  background: #fff;
  border-radius: 0.625rem;
} */
.main .payment_info_sec .w_80 {
  display: flex;
}

.main .payment_info_row .label {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
}
.main .payment_info_row .label span {
  font-size: 1rem;
  font-weight: 400;
  color: #757575;
  margin-left: 8px;
}
.main .all_pay {
  margin: 1.875rem 0;
  font-size: 1.25rem;
}
.main .all_pay b {
  font-size: 2.375rem;
  font-weight: 700;
  margin-right: 3px;
}
.main .pay_detail dl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main .pay_detail dl + dl {
  margin-top: 0.625rem;
}
.main .pay_detail dl dt {
  color: #454545;
}
.main .pay_detail dl:first-child dd {
  color: #4280ff;
}
.main .pay_detail dl:last-child dd {
  color: #ff2c27;
}

.main .color-card-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.875rem;
  border-radius: 0.625rem;
}

.main .color-card-body .left p {
  font-size: 1.125rem;
  font-weight: 500;
  color: #fff;
}
.main .color-card-body .left span {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.4);
  display: block;
  margin-top: 0.625rem;
}
.main .color-card-body .right {
  font-size: 1.25rem;
  color: #fff;
}

.main .color-card-body .right b {
  font-size: 2.375rem;
  font-weight: 700;
  margin-right: 3px;
}

.main .management_info {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 0.625rem;
  margin-bottom: 1.25rem;
}
.main .management_info .ico_ttl {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 500;
  width: 11.875rem;
}
.main .management_info .ico_ttl i {
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  background: #3a82f8 no-repeat center;
  background-image: url(../img/common/company_ico.png);
  border-radius: 6px;
  margin-right: 8px;
}
.main .management_info ul {
  display: flex;
  align-items: center;
  width: calc(100% - 11.875rem);
}
.main .management_info ul li{
  flex: 1;
}
.main .management_info ul .link_go {
  color: #454545;
}
.main .management_info ul .link_go span {
  color: #4280ff;
  margin-left: 0.75rem;
}
.calculate_sec .graph_cont {
  width: 100%;
}
.graph_cont {
  background: #fff;
  padding: 1.5rem 1.5rem 0;
  border-radius: 0.625rem;
}
#chart * {
  font-family: "Spoqa Han Sans Neo" !important;
}

#chart .apexcharts-tooltip {
  min-width: 13.125rem;
}
#chart .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  border: none !important;
  background: #fff !important;
  font-size: 1.125rem !important;
  padding: 1.25rem;
  margin: 0;
}
#chart .apexcharts-yaxis-texts-g text {
  fill: #757575;
  font-size: 0.9375rem;
}
#chart .apexcharts-xaxis text {
  fill: #757575;
  font-size: 0.9375rem;
}
#chart .apexcharts-tooltip-series-group {
  padding: 0 1.25rem;
  display: none;
  text-align: left;
  justify-content: left;
  align-items: center;
}
#chart .apexcharts-tooltip-series-group .apexcharts-tooltip-text-y-value {
  color: #4280ff;
  font-weight: 500;
}
#chart .apexcharts-tooltip-series-group + .apexcharts-tooltip-series-group {
  padding-bottom: 0.875rem;
}
#chart
  .apexcharts-tooltip-series-group
  + .apexcharts-tooltip-series-group
  .apexcharts-tooltip-text-y-value {
  color: #ff2c27;
}
#chart .apexcharts-tooltip-marker {
  display: none;
}
#chart .apexcharts-tooltip-text {
  width: 100%;
  font-size: 1rem !important;
}
#chart .apexcharts-tooltip-y-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
#chart .apexcharts-legend-text {
  font-size: 1rem !important;
  color: #757575 !important;
}
#chart .apexcharts-legend {
  position: inherit !important;
  margin-left: 57%;
}
#chart .apexcharts-xaxistooltip {
  display: none !important;
}

.main .cal_item .top .sub_ttl {
  font-size: 1.125rem;
  font-weight: 500;
}
.main .cal_item .top .sub_ttl span {
  font-size: 1rem;
  color: #757575;
  font-weight: 400;
  margin-left: 6px;
}
.main .cal_item .bot_list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.625rem;
}
.main .cal_item .bot_list li:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}
.main .cal_item .bot_list li P {
  display: flex;
  align-items: center;
  color: #454545;
}
.main .cal_item .bot_list li i {
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  background: no-repeat center;
  border-radius: 0.875rem;
  margin-right: 0.625rem;
}
.main .cal_item .bot_list li.cont_01 i {
  background-color: #699ff7;
  background-image: url(../img/common/cal_ico_01.png);
}
.main .cal_item .bot_list li.cont_02 i {
  background-color: #ffa001;
  background-image: url(../img/common/cal_ico_02.png);
}
.main .cal_item .bot_list li.cont_03 i {
  background-color: #ff6761;
  background-image: url(../img/common/cal_ico_03.png);
}
.main .cal_item .bot_list li.cont_04 i {
  background-color: #49c49d;
  background-image: url(../img/common/cal_ico_05.png);
}
.main .cal_item .bot_list li.cont_05 i {
  background-color: #bcbecb;
  background-image: url(../img/common/cal_ico_06.png);
}
.main .cal_item .bot_list li em {
  color: #ff2c27;
}

.bod_list_cont .ttl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.875rem;
}
.bod_list_cont .ttl strong {
  font-size: 1.125rem;
  font-weight: 500;
}
.bod_list_cont .ttl a {
  color: #454545;
  display: flex;
  align-items: center;
}
.bod_list_cont .ttl a i {
  margin-left: 6px;
}
.bod_list_cont .bod_list a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bod_list_cont .bod_list a .sb_ttl {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #454545;
}
.bod_list_cont .bod_list a .date {
  color: #757575;
  margin-left: 0.625rem;
}
.bod_list_cont .bod_list li + li {
  margin-top: 0.625rem;
}

/* ============================================================== */
.flex {
  display: flex;
  align-items: center;
}
.top_table_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.875rem;
}
.top_table_btn * + * {
  margin-left: 0.625rem;
}
.btn_st_01 {
  border: 1px solid #dbdbdb;
  height: 32px;
  line-height: 32px;
  width: 6.875rem;
  font-size: 0.9375rem;
  color: #353535;
  border-radius: 4px;
}
.top_table_btn .wid_auto {
  width: inherit;
  padding: 0 0.625rem;
}
.black_btn {
  background: #454545;
  color: #fff;
  border-color: #454545;
}
.btn_st_01.blue {
  color: #fff;
  background: var(--bs-deep-point);
  border-color: var(--bs-deep-point);
}

.btn_st_02 {
  height: 2rem;
  display: block;
  padding: 0 0.625rem;
  font-size: 0.9375rem;
  line-height: 1.875rem;
  background: #fff;
  border-radius: 4px;
  border: 1px solid;
}
.btn_st_02.blue_line {
  color: var(--bs-point);
}
.btn_st_02.blue_bg {
  background: var(--bs-point) !important;
  color: #fff;
}
.btn_st_02.gray_line {
  color: #353535;
  border-color: #dbdbbd;
}
.btn_st_02.red {
  background: #ff7c25;
  border: 1px solid #da5800;
}
.btn_st_02.blue {
  color: #fff;
  background: var(--bs-deep-point);
}
.btn_st_02.green {
  background: #60c736;
  border: 1px solid #429e21;
}

.els_btn {
  display: flex;
  border: 1px solid #1d7a4b;
  color: #fff;
  background: #1e7145;
  padding: 0 0.9375rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  font-size: 0.9375rem;
  border-radius: 4px;
  font-weight: 400;
}
.els_btn i {
  display: block;
  width: 1.375rem;
  height: 21px;
  background: url(../img/common/esl_btn.png) no-repeat center;
  margin-right: 6px;
}

.in_box.search_box {
  position: relative;
}
.btn_st_02.search {
  display: block;
  width: 2.625rem;
  background: url(../img/common/search_btn.png) no-repeat center;
  border: 1px solid #dbdbdb;
  font-size: 0;
  color: transparent;
}
.search_box .btn_st_02.search {
  position: absolute;
  top: 50%;
  right: 0;
  width: 2.25rem;
  transform: translateY(-50%);
  border: none;
}
.top_filter .els_btn,
.top_filter .btn_st_02 {
  padding: 0 0.875rem;
  border-radius: 8px;
}
.btn_area_ttl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn_area_ttl .right {
  display: flex;
  align-items: center;
}
.btn_area_ttl .right * + * {
  margin-left: 0.625rem;
}

.pager {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.875rem;
}
.pager li + li {
  margin-left: 5px;
}
.pager li a {
  display: block;
  padding: 0 5px;
  line-height: 1.875rem;
  min-width: 1.875rem;
  text-align: center;
  background: #efefef;
  font-size: 0.9375rem;
  border-radius: 8px;
}
.pager li.on a {
  background: #1958f5;
  color: #fff;
  box-shadow: 0px 4px 7px 0px rgba(25, 88, 245, 0.33);
}
.pager li.prev_btn {
  margin-right: 0.9375rem;
}
.pager li.next_btn {
  margin-left: 1.25rem;
}

#container {
  width: calc(100% - var(--nav-width));
  margin-top: var(--header-height);
  padding: 1.875rem;
  box-sizing: border-box;
}
#container.main {
  background: #f6f8fc;
}
#container .top_table,
#container .top_table table {
  width: 100%;
}
#container .top_table table {
  table-layout: fixed;
}
#container .top_table table th {
  background: #f5f5f5;
  border: 1px solid #e2e2e2;
  padding: 1.25rem 0.9375rem;
  text-align: left;
  vertical-align: middle;
  font-size: 0.9375rem;
}
#container .top_table table td {
  padding: 0.4375rem 1.25rem;
  border: 1px solid #e2e2e2;
  font-size: 0.9375rem;
  vertical-align: middle;
}
#container .top_table .btn_row {
  display: flex;
  align-items: center;
  margin: 0 0.9375rem;
}
#container .top_table .btn_row li + li {
  margin-left: 6px;
}
#container .top_table .btn_row button {
  background: #999999;
  border: 1px solid #909090;
  width: 3rem;
  line-height: 2.5rem;
  color: #fff;
  font-size: 0.9375rem;
  border-radius: 2px;
}
#container .top_table .btn_row .on button {
  background: var(--bs-point);
  border-color: #2780c5;
}
#container .top_table .sel_box {
  width: calc(100% - 13.125rem);
}
#container .top_table .sel_box.w_100 {
  width: 100%;
}
#container .top_table select {
  height: 32px;
  /* height: 2.625rem; */
  /* max-width: 12.5rem; */
  width: 100%;
  padding-right: 1.875rem;
  border-radius: 4px;
  text-overflow: ellipsis;
}
#container .top_table .btn_st_02 {
  /* line-height: 2.5rem;
  height: 2.625rem;
  padding: 0 0.9375rem; */
  line-height: 32px;
  height: 32px;
  padding: 0 0.9375rem;  
}
#container .top_table select + select {
  margin-left: 0.625rem;
}
#container .top_table input {
  line-height: 32px;
  font-size: 0.9375rem;
  border-radius: 4px;
  height: 32px;
}

#container .top_table p + p,
#container .top_table input + p {
  margin-top: 6px;
}
#container .top_table .in_box {
  width: calc(100% - 13.125rem);
}
/* #container .top_table .flex .in_box  {
    width: 50%;
} */
#container .top_table .sel_box + .in_box {
  margin-left: 0.625rem;
}
.mr_10 {
  margin-right: 0.625rem;
}
.table_cont_1 {
  max-width: 21.1875rem;
  width: 100%;
}
#container .flex.table_cont_1 .sel_box {
  width: 100%;
}
#container .wd_auto {
  width: auto !important;
}

#container .top_table .check_box input + label span {
  margin-right: 0.625rem;
}

.top_filter {
  display: flex;
  align-items: center;
}
.top_filter input,
.top_filter select {
  padding-right: 1.875rem;
  height: 2.625rem;
  font-size: 0.9375rem;
  border-radius: 8px;
}
.top_filter input:focus,
.top_filter select:focus {
  border-color: var(--bs-deep-point);
}
.form_item select option[value=""][disabled],
.top_filter select option[value=""][disabled] {
  display: none;
}
.top_filter .date_box input {
  background: no-repeat center right 0.625rem;
  background-image: url(../img/common/date_ico.png);
}
.top_filter .btn_box {
  margin: 0;
}
.top_filter div + .btn_box,
.top_filter div + div {
  margin-left: 0.625rem;
}

.top_filter a,
.top_filter button {
  font-size: 0.9375rem;
  height: 2.625rem;
  line-height: 2.5rem;
}

#container .bot_item {
  margin-top: 1.875rem;
}
#container .bot_item .table_box {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
#container .bot_item .txt {
  background: #6f7684;
  border-bottom: 1px solid #eee;
  padding: 0.625rem 1.25rem;
}
#container .bot_item .txt span {
  display: block;
  font-size: 0.8125rem;
  color: #fff;
  line-height: 1.5;
}
#container .bot_item table {
  white-space: nowrap;
  min-width: 800px;
  border-top: 1px solid #dbdbdb;
  width: 100%;
}
#container .bot_item table tbody tr:hover td,
#container .bot_item table tbody tr:hover {
  background-color: #f7f7f7 !important ;
}
#container .bot_item table tbody tr.total:hover td,
#container .bot_item table tbody tr.total:hover {
  background-color: #fff !important;
}
#container .bot_item table th {
  padding: 0.875rem 1.25rem;
  font-size: 0.9375rem;
  color: #252525;
  border-bottom: 1px solid #ededed;
  vertical-align: middle;
}
#container .bot_item table .total th {
  background: #f5f9f8;
}
#container .bot_item table .total span {
  display: block;
  color: #252525;
  text-align: left;
  line-height: 1.5;
}
#container .bot_item table th.th_top {
  border-bottom: 1px solid #eeeeee;
}
#container .bot_item table th.right_line,
#container .bot_item table th.left_line {
  position: relative;
}
#container .bot_item table th.left_line::after {
  content: "";
  display: block;
  width: 1px;
  height: 1.125rem;
  background: #ededed;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
#container .bot_item table th.right_line::before {
  content: "";
  display: block;
  width: 1px;
  height: 1.125rem;
  background: #ededed;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
#container .bot_item table .blue_bg {
  background: #f8faff;
}
#container .bot_item table .red_bg {
  background: #fffaf8;
}
#container .bot_item table th.purple_bg {
  background: #925dff;
}
#container table th .red,
#container table td span.red {
  color: var(--bs-red);
}

#container .bot_item table .purple_txt {
  color: #5e3eeb;
}
#container .bot_item table .blue_txt {
  color: #4280ff;
}
#container .bot_item table .red_txt {
  color: #ff2c27;
}

#container .bot_item table th + th {
  /* border-left: 1px solid #eeeeee */
}
#container .bot_item table td {
  padding: 0.75rem 1.25rem;
  /* font-size: 0.9375rem; */
  font-size: 14px;
  vertical-align: middle;
  text-align: center;
  line-height: 1.3;
  color: #353535;
}
#container .bot_item table td.blue_bg {
  background: #f7f9ff;
}
#container .bot_item table td.red_bg {
  background: #fff7f7;
}
#container .bot_item table td.purple_bg {
  background: #f9f7ff;
}
#container .bot_item table td .no_data {
  padding: 0.9375rem;
  display: block;
}

#container .bot_item table td + td {
  /* border-left: 1px solid #eeeeee */
}
#container .bot_item table td.orange {
  color: orange !important;
}
#container .bot_item table td.red {
  color: var(--bs-red) !important;
}
#container .bot_item table .total {
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #eeeeee;
}
#container .bot_item table thead .total td {
  color: #252525;
  border-bottom: 1px solid #ededed;
}
#container .bot_item table .total td {
  padding: 1.25rem 0.875rem;
  font-weight: 700;
}
#container .bot_item table .total:hover {
  background: #fff;
}
#container .check_box input[type="checkbox"] {
  display: none;
}
#container .check_box input + label {
  display: flex;
  align-items: center;
}
#container .check_box input + label span {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background: no-repeat center;
  background-image: url(../img/common/check.png);
  margin-right: 1.25rem;
}
#container .check_box input:checked + label span {
  background-image: url(../img/common/check_on.png);
}
#container .bot_item table td span.red {
  color: var(--bs-red) !important;
  margin-left: 4px;
}
#container .bot_item table td span.blue {
  color: var(--bs-point) !important;
}
#container .bot_item table td.left {
  text-align: left;
}
#container .bot_item table td .opction {
  display: inline-block;
  color: #fff;
  background: #62c938;
  border: 1px solid #429e21;
  padding: 2px 3px;
  border-radius: 3px;
}
#container table .radio_wrap {
  display: flex;
}
#container table .radio_box + .radio_box {
  margin-left: 1.25rem;
}
#container table .btn_wrap {
  display: flex;
  justify-content: center;
}
#container table .btn_wrap * + * {
  margin-left: 0.625rem;
}
#container .bot_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.625rem;
}
#container .bot_info .left {
  font-weight: 700;
  font-size: 0.9375rem;
}
#container .bot_info .right {
  display: flex;
}
#container .bot_info .right button + button {
  margin-left: 0.625rem;
}
#container input[type="radio"] {
  display: none;
}
#container input[type="radio"] + label {
  padding-left: 1.5625rem;
  background: no-repeat center left / 1.25rem auto;
  background-image: url(../img/common/radio.png);
  font-size: 0.9375rem;
  display: inline-block;
  line-height: 1.25rem;
}
#container input[type="radio"]:checked + label {
  background-image: url(../img/common/radio_on.png);
}
#container .form_item + .form_item {
  margin-top: 35px;
}
#container .form_item .label {
  font-size: 1.0625rem;
  font-weight: 500;
  padding-bottom: 0.625rem;
}
#container .form_item .gray_btn {
  border: 1px solid #dbdbdb;
  background: #fafafa;
  height: 1.625rem;
  border-radius: 3px;
  padding: 0 5px;
  margin-left: 0.625rem;
}
#container table a.view_edit {
  color: var(--bs-point) !important;
}

#container .form_item .flex .sel_box + .sel_box {
  margin-left: 0.625rem;
}
#container .form_item .flex .check_box {
  margin-right: 0.625rem;
}

#container .form_item .flex.subs div {
  display: flex;
  align-items: center;
}
#container .form_item .flex.subs div + div {
  margin-left: 0.625rem;
}

#container .form_item .text_area {
  width: calc(100% - 13.125rem);
}
#container .form_item .text_area textarea {
  resize: none;
  padding: 0.625rem;
  line-height: 1.5;
  height: 6.25rem;
  overflow: auto;
}

#container .form_item .in_box input[type="file"] {
  border: none;
}
#container .calendar_box {
  margin-top: 3.125rem;
  margin-bottom: 6.25rem;
}

/* calendar */
.calendar_table thead {
}
.calendar_table thead th {
  vertical-align: middle;
  padding: 1.875rem 0;
}
.calendar_table thead tr:nth-of-type(1) th {
  height: 2.75rem;
}
.calendar_table thead tr:nth-of-type(2) th {
  height: 74px;
  font-size: 0.9375rem;
}
.calendar_table .head_nav {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.calendar_table .head_nav h3 {
  font-size: 1.875rem;
  font-weight: 700;
  padding: 0 1.25rem;
}
.calendar_table .head_nav .btn {
  width: 3.75rem;
  height: 3.75rem;
  background: url(../img/common/datepicker_prev_w.png) no-repeat center /
    3.75rem auto;
}
.calendar_table .head_nav .next {
  transform: rotate(180deg);
}
.calendar_table .head_nav .btn:hover {
  background-image: url(../img/common/datepicker_prev_c.png);
}
.calendar_table {
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #e2e2e2;
  width: 100%;
}
.calendar_table tr {
  border-bottom: 1px solid #ededed;
}
.calendar_table thead th + th {
  border-left: 1px solid #ededed;
}
.calendar_table tbody td {
  border-left: 1px solid #ededed;
  border: 1px solid #ededed;
  font-size: 0.9375rem;
  font-weight: 700;
  color: #bbbbbb;
  word-break: keep-all;
  padding: 1.25rem;
  box-sizing: border-box;
  vertical-align: top;
  position: relative;
  cursor: pointer;
}
.calendar_table tbody td.active,
.calendar_table tbody td.on {
  background: #f4f7ff;
}
.calendar_table tbody td.active::after,
.calendar_table tbody td.on::after {
  content: "";
  display: block;
  width: 3px;
  height: 100%;
  background: var(--bs-point);
  position: absolute;
  top: 0;
  left: 0;
}

.calendar_table tbody td .date_wrap {
  min-height: 90px;
  position: relative;
  padding-top: 1.25rem;
  box-sizing: border-box;
}
.calendar_table tbody td .date {
  position: absolute;
  top: 0;
  right: 0;
}
.calendar_table tbody td ul {
  display: inline-block;
  width: 100%;
}

.calendar_table tbody td ul li {
  position: relative;
  display: flex;

  justify-content: space-between;
}
.calendar_table tbody td ul li .all_text_box {
  position: absolute;
  left: 100%;
  top: 0;
  padding: 1.25rem;
  width: 350px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 0.625rem;
  z-index: 10;
}
.calendar_table tbody td ul li .all_text_box p {
  line-height: 1.333;
}
.calendar_table tbody td ul li:first-of-type {
  padding-top: 0;
}
.calendar_table tbody td ul li:last-of-type {
  padding-bottom: 0;
}
.calendar_table tbody td ul li strong {
  display: block;
  margin-bottom: 5px;
  line-height: 1.333;
  color: #353535;
}
.calendar_table tbody td ul li .left p {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--bs-point);
}
.calendar_table tbody td ul li .right button {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background: no-repeat center;
  background-image: url("../img/common/ico_question.png");
  margin-top: 4px;
}

.calendar_table tbody td:first-of-type .date,
.calendar_table thead th.sun {
  color: #ff2c27;
}

.calendar_table tbody td:last-of-type .date,
.calendar_table thead th.sat {
  color: var(--bs-deep-point);
}

/* table_layout02 */
.table_layout02_wrap {
  white-space: nowrap;
  overflow-x: auto;
}
.table_layout02 {
  width: auto;
  min-width: 100%;
  white-space: nowrap;
}
.table_layout02 thead tr {
  border-bottom: 1px solid rgba(246, 252, 255, 0.4);
}
.table_layout02 tr {
  border-bottom: 1px solid #eeeeee;
}
.table_layout02 tr.red_bg {
  background-color: rgba(255, 97, 93, 0.05);
}
.table_layout02 tr.red_bg th {
  color: var(--bs-red);
}
.table_layout02 tr.red_bg td {
  color: var(--bs-red);
}
.table_layout02 tr.red_bg td span {
  color: var(--bs-red);
}
.table_layout02 tr.total_list {
  background-color: #cef6e2;
}
.table_layout02 tr.total_list td,
.table_layout02 tr.total_list td span {
  font-weight: bold;
}
/* .table_layout02 tr:hover {
    background-color:rgba(209,235,235,.3);
} */
.table_layout02 tr th {
  padding: 0.9375rem 0.625rem;
  font-size: 0.9375rem;
  color: #353535;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  /* background-color:#6f7684;
    border-left:1px solid rgba(246,252,255,.4);
    border-right:1px solid rgba(246,252,255,.4); */
}
.table_layout02 tr th.blue_bg {
  background-color: var(--bs-point);
}
.table_layout02 tr th.red_bg {
  background-color: var(--bs-red-bg);
}
.table_layout02 tr th button {
  padding: 0 0.9375rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  line-height: 1.5;
  background-position: right 5px center;
  background-repeat: no-repeat;
}
/* .table_layout02 tr th button.up {
    background-image:url('../img/ico/ico_align_up.png');
}
.table_layout02 tr th button.down {
    background-image:url('../img/ico/ico_align_down.png');
} */
.table_layout02 tr th .check_box label {
  padding-left: 21px;
  font-weight: bold;
  color: #fff;
}
.table_layout02 tr td {
  padding: 0.625rem 1.25rem;
  font-size: 0.9375rem;
  color: #454545;
  text-align: center;
  /* border-left:1px solid #eee;
    border-right:1px solid #eee; */
}
.table_layout02 tr td.blue_bg {
  background-color: rgba(93, 146, 255, 0.05);
}
.table_layout02 tr td.red_bg {
  color: var(--bs-red);
  background-color: rgba(255, 97, 93, 0.05);
}
.table_layout02 tr td.red_txt {
  color: var(--bs-red);
}
.table_layout02 tr td span {
  font-size: 0.75rem;
  color: #454545;
  text-align: center;
}
.table_layout02 tr td.red_bg span {
  color: var(--bs-red);
}
.table_layout02 tr td.red_txt span {
  color: var(--bs-red);
}
.table_layout02 tr td span.red_txt {
  color: var(--bs-red);
}
.table_layout02 tr td span.blue_txt {
  color: var(--bs-point);
}
.table_layout02 tr td.point_link {
  color: var(--bs-point);
  cursor: pointer;
}
.table_layout02 tr td.point_link span {
  color: var(--bs-point);
}
.table_layout02 .setting_btn_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table_layout02 .setting_btn_wrap button + button {
  margin-left: 0.625rem;
}
/* .table_layout02 .img_set {
    width: 0.75rem;
    height: 0.75rem;
    background: url(../../img/ico/ico_setting_off.png) no-repeat center center / cover;
}
.table_layout02 .img_set:hover {
    background: url(../../img/ico/ico_setting_on.png) no-repeat center center / cover;
}
.table_layout02 .img_del {
    width: 0.875rem;
    height: 0.875rem;
	background: url(../../img/ico/ico_del_off.png) no-repeat center center / cover;
}
.table_layout02 .img_del:hover {
	background: url(../../img/ico/ico_del_on.png) no-repeat center center / cover;
} */

/* //table_layout02 */

.btn_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.25rem 0;
}
.pop_wrap .btn_box a {
  /* display: block;
    min-width: 6.875rem;
    padding: 0 0.625rem;
    line-height: 1.875rem;
    font-size: 0.75rem;
    font-weight: bold;
    border: 1px solid transparent;
   
    box-sizing: border-box; */
  text-align: center;
}
.btn_box button + a {
  margin-left: 0.625rem;
}
.btn_box a + button {
  margin-left: 0.625rem;
}
.btn_box button + button {
  margin-left: 0.625rem;
}
.btn_box a + a {
  margin-left: 0.625rem;
}

.btn_box .dark_full_btn {
  color: #fff;
  background-color: #454545;
  border-color: #454545;
}
.btn_box .gray_line_btn {
  color: #333333;
  border-color: #dbdbdb;
}
.btn_box .dark_line_btn {
  color: #454545;
  border-color: #454545;
}

.detail_btn {
  padding: 0 0.625rem;
  height: 1.5625rem;
  line-height: 1.5rem;
  background-color: var(--bs-point);
  font-size: 0.75rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  border-radius: 0.9375rem;
}

/* 팝업 */
.pop_common {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  display: none;
  /* display: flex; */
}
.pop_common.show {
  display: flex;
}

.pop_common .pop_cont {
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pop_common .pop_cont .pop_top {
  background: #fff;
  border-radius: 0.625rem 0.625rem 0 0;
  padding: 0.625rem 0.625rem 1.875rem;
  display: flex;
  flex-direction: column-reverse;
}
.pop_common .pop_cont .pop_top h3 {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
.pop_common .pop_cont .pop_top button {
  margin-left: auto;
}

.pop_common .pop_cont .pop_item {
  background: #fff;
  padding: 0 1.25rem 2.5rem;
  border-radius: 0 0 0.625rem 0.625rem;
  margin-top: -1px;
}
.pop_common .pop_cont .pop_item .pop_table table {
  width: 100%;
  border-top: 1px solid #252525;
}

.pop_common .pop_cont .pop_item .pop_table table th {
  background: #fafafa;

  padding: 0.9375rem 0.625rem;
  font-weight: 500;
}
.pop_common .pop_cont .pop_item .pop_table table td {
  text-align: center;
  padding: 0.625rem 0.9375rem;
}
.pop_common .pop_cont .pop_item .pop_table table tr {
  border-bottom: 1px solid #dbdbdb;
}

.pop_common .pop_cont .pop_item .pop_table table td button {
  margin: 0 auto;
}
.pop_common .pop_cont .pop_item .flex_box {
  display: flex;
  align-items: center;
  width: 100%;
}

.pop_common .pop_cont .pop_item .button_row button {
  width: 7.5rem;
  border: 1px solid var(--bs-point);
  color: var(--bs-point);
  flex: 0 0 auto;
  margin: 0 0 0 0.625rem !important;
  height: 2.875rem;
  border-radius: 6px;
}

.pop_common .pop_cont .pop_item .button_row input {
  width: calc(100% - 7.5rem);
  height: 2.875rem;
  border-radius: 6px;
}
.pop_common .pop_cont .pop_item .pop_table input[type="radio"] + label {
  font-size: 1.0625rem !important;
}

.pop_common .pop_cont .pop_item .email {
  display: flex;
  align-items: center;
  width: calc(100% - 7.5rem);
}

.pop_common .pop_cont .pop_item .email span {
  margin: 0 6px;
}
.pop_common .pop_cont .pop_item .email select,
.pop_common .pop_cont .pop_item .email input {
  width: calc(33.333% - 0.625rem);
}
.pop_common .pop_cont .pop_item .button_row select {
  height: 2.875rem;
  margin-right: 0.625rem;
  border-radius: 6px;
}

.pop_common .pop_cont .pop_item input[type="text"] {
  height: 2.875rem;
  border-radius: 6px;
}

.pop_common .pop_cont .btn_box {
  margin-top: 1.25rem;
}

.pop_common .pop_cont .btn_box a {
  width: 6.25rem;
  margin: 0 auto;
  display: block;
  text-align: center;
  line-height: 2.5rem;
  font-size: 1rem;
}

.ip_pop .pop_cont {
  padding-bottom: 1px;
}

.ip_pop table colgroup {
  padding-top: 1px;
}

.ip_pop .flex_box input[type="text"] {
  max-width: 6.25rem;
}

.ip_pop .flex_box.ip_box span {
  margin: 0 4px;
}

.pop_common .pop_inner span.txt {
  line-height: 1.5;
  display: block;
}

.pop_common .pop_inner em {
  text-align: center;
  margin-top: 1.25rem;
  display: block;
  font-weight: 500;
}

.pop_common .btn_box .btn_st_02 {
  max-width: 14.375rem;
  width: 100%;
  line-height: 2.625rem;
  height: 2.625rem;
  font-size: 0.9375rem;
}
.pop_common .btn_box .btn_st_02.w_230 {
  width: 100% !important;
  line-height: 2.625rem !important;
  height: 2.625rem !important;
}

.intro_pop .intro_btn {
  margin-bottom: 1.25rem;
}

.intro_pop .intro_btn,
.intro_pop .confirm_tab {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro_pop .intro_btn a,
.intro_pop .btn_box.confirm_tab a,
.intro_pop .list_btn {
  border: 1px solid var(--bs-point);
  margin: 0;
  color: var(--bs-point);
  border-radius: 3px;
}

.intro_pop .list_btn {
  padding: 0 0.625rem;
  line-height: 1.75rem;
  margin-left: 0.625rem;
}

.intro_pop .btn_box.confirm_tab a.on {
  background: var(--bs-point);
  color: #fff;
}

.intro_pop .btn_box.confirm_tab a + a {
  margin-left: 0.625rem;
}

.intro_pop .intro_btn.btn_box a {
  width: inherit !important;
  min-width: 6.875rem;
  padding: 0 1.25rem;
  margin: 0;
}

.intro_pop .intro_btn a.dark_full_btn {
  background: var(--bs-point);
  color: #fff;
  border-color: var(--bs-point);
}

.intro_pop .intro_btn.btn_box a + a {
  margin-left: 0.625rem;
}

.intro_pop .tab_wrap {
  margin-top: 1.25rem;
}

.intro_pop .tab_wrap .tab {
  display: none;
}

.intro_pop .login_add_radio {
  margin-top: 1.25rem;
}

.intro_pop .login_add_radio ul {
  border-top: 1px solid #252525;
}

.intro_pop .login_add_radio ul li {
  border-bottom: 1px solid #dbdbdb;
  padding: 0.625rem;
}

.intro_pop .radio_box input[type="radio"],
.intro_pop .login_add_radio input[type="radio"] {
  display: none;
}

.intro_pop .login_add_radio input[type="radio"] + label,
.intro_pop .radio_box input[type="radio"] + label {
  padding-left: 1.5625rem;
  background: no-repeat center left / 1.25rem auto;
  background-image: url(../img/common/radio.png);
  display: inline-block;
  line-height: 1.25rem;
  cursor: pointer;
}

.intro_pop .radio_box input[type="radio"]:checked + label,
.intro_pop .login_add_radio input[type="radio"]:checked + label {
  background-image: url(../img/common/radio_on.png);
}

.intro_pop .radio_box + .radio_box {
  margin-left: 0.625rem;
}

.intro_pop .table_layout01 th {
  line-height: 1.5;
  vertical-align: middle;
}

.intro_pop .table_layout01 td {
  text-align: left !important;
  vertical-align: middle;
}

.intro_pop .flex_box #account_num1 {
  max-width: 12.5rem;
}

.intro_pop.id_find_popup .pop_table,
.intro_pop.pw_find_popup .pop_table,
.intro_pop.login_confirm03 .pop_table,
.intro_pop.login_confirm02 .pop_table {
  margin-top: 1.25rem;
}

.intro_pop.pw_find_popup .pop_table input[type="text"],
.intro_pop.id_find_popup .pop_table input[type="text"] {
  max-width: 15.625rem;
}

.intro_pop .pop_table .unit {
  margin-left: 0.625rem;
}

/* popup */

.pop_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: none;
}
.pop_wrap .dim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.pop_wrap .pop_cont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: #fff;
  z-index: 99;
}
.pop_wrap .pop_top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 3.75rem;
  background-color: #2b333e;
}
.pop_wrap .pop_top .pop_ttl {
  font-family: "Noto Sans KR", "돋움", "dotum", sans-serif;
  font-weight: 400;
  color: #ffffff;
}
.pop_wrap .pop_top .btn_closed {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 3.75rem;
  height: 3.75rem;
}
.pop_wrap .pop_body .pop_inner {
  padding: 1.25rem;
}
.pop_wrap.pop440 .pop_cont {
  width: 440px;
}
.pop_wrap.pop500 .pop_cont {
  width: 500px;
}
.pop_wrap.pop680 .pop_cont {
  width: 680px;
}
.pop_wrap .tab_wrap .tab {
  display: none;
}
.pop_wrap .inner_top {
  margin: 0.625rem 0;
}
.pop_wrap .inner_bottom {
  margin: 0.625rem 0;
}
.pop_wrap .login_add_radio ul {
  border-top: 1px solid #000;
}
.pop_wrap .login_add_radio ul li {
  padding: 0.625rem;
  border-bottom: 1px solid #ededed;
}
.pop_wrap .txt {
  display: block;
  font-size: 0.8125rem;
  color: #757575;
  line-height: 1.5;
}
.pop_wrap .txt_note {
  display: block;
  margin: 0.625rem 0;
  font-size: 0.8125rem;
  color: #757575;
  line-height: 1.5;
}
.pop_wrap em {
  display: block;
  margin: 0.625rem 0;
  font-size: 0.875rem;
  color: #252525;
  line-height: 1.5;
  text-align: center;
}

/*1:1 문의 관련 추가부분*/

.board_view {
  border-top: 1px solid #454545;
}
/* .board_view .bd_info .fl_sb, */
.board_view > div {
  border-bottom: 1px solid #dbdbdb;
}
.board_view .bd_top {
  background: #f5f5f5;
  text-align: center;
  padding: 1.25rem;
}
.board_view .bd_top .ttl {
  line-height: 1.5;
  font-size: 1.125rem;
  word-break: keep-all;
}
.board_view .bd_info > div {
  padding: 1.25rem;
}
.board_view .bd_info P {
  font-size: 1rem;
  color: #757575;
}
.board_view .bd_info P span {
  color: #252525;
}
.board_view .bd_info .left {
  display: flex;
  align-items: center;
}
.board_view .bd_info .left p + p {
  padding-left: 0.75rem;
  margin-left: 0.75rem;
  position: relative;
}
.board_view .bd_info .left p + p::after {
  content: "";
  display: block;
  width: 1px;
  height: 0.8125rem;
  background: #e2e2e2;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.board_view .bd_info .flex_box p {
  display: flex;
  align-items: center;
}
.board_view .bd_info a {
  display: flex;
  align-items: center;
}
.board_view .bd_info a span {
  border-bottom: 1px solid;
  margin-left: 4px;
}

.board_view .bd_body {
  padding: 1.25rem 1.25rem 7.5rem;
  font-size: 1rem;
  line-height: 1.625;
}
.board_view .bd_answer {
  margin-top: 1.25rem;
}
.board_view .bd_answer span {
  background: #f5f5f5;
  border: 1px solid #dbdbdb;
  padding: 0 1.25rem;
  line-height: 2.625rem;
  font-size: 1rem;
  font-weight: 500;
  display: block;
}
.board_view .bd_answer > div {
  padding: 1.25rem;
  border: 1px solid #dbdbdb;
  border-top: none;
  border-bottom: none;
  font-size: 1rem;
  line-height: 1.625;
}

/* pop_open */

/* ---------------------- 모바일 css */
html.mo {
  max-width: 768px;
  margin: 0 auto;
}
#wrap_mo #header {
  background: #22345e;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 768px;
}
#wrap_mo #header .logo {
  max-width: 8.125rem;
  display: flex;
}
#wrap_mo #container {
  width: 100%;
  margin-top: 3.75rem;
  padding: 35px 0.9375rem;
}
.mo_page_ttl {
  font-size: 1.5rem;
  color: #3d3d3d;
  margin-bottom: 1.875rem;
}
.pay_st_item + .pay_st_item {
  margin-top: 1.875rem;
}
.pay_st_item .label {
  font-size: 1.125rem;
  color: #3d3d3d;
}

.pay_st_item .pay_totoal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pay_st_item .pay_totoal p {
  font-size: 0.875rem;
  font-family: "NanumSquare";
  color: #3f73e3;
  transform: skew(-0.1deg);
}
.pay_st_item .pay_totoal p strong {
  font-size: 1.625rem;
  font-weight: 900;
  margin-right: 2px;
}

.pay_st_item .pay_st_list {
  margin-top: 1.25rem;
}

.pay_st_item .pay_st_list dt {
  font-size: 0.8125rem;
  color: #454545;
  margin-bottom: 0.625rem;
}
.pay_st_item .pay_st_list dd select,
.pay_st_item .pay_st_list dd input[type="text"],
.pay_st_item .pay_st_list dd input[type="password"] {
  color: #353535;
  font-size: 0.8125rem;
  height: 2.5rem;
  padding: 0 0.9375rem;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
}
.pay_st_item .pay_st_list dd input:disabled {
  border: none;
  background: #f7f7f7;
}
.pay_st_item .pay_st_list dd select {
  color: #999999;
}

.pay_st_item .pay_st_list dl + dl {
  margin-top: 1.25rem;
}
.pay_st_item .pay_st_list dd .flex {
  width: 100%;
}
.pay_st_item .pay_st_list dd .flex .in_box {
  width: 80%;
}
.pay_st_item .pay_st_list dd p {
  font-size: 0.8125rem;
  margin-left: 0.625rem;
}

.pay_st_item .pay_st_list .radio_wrap {
  display: flex;
}
.pay_st_item .pay_st_list .radio_wrap .radio_box {
  width: 50%;
}
.pay_st_item .span.blue {
  color: #3f73e3;
}
.mo_page_btn {
  margin-top: 3.75rem;
}

.mo_page_btn .btn_st_03 {
  display: block;
  width: 100%;
  background: var(--bs-deep-point);
  line-height: 2.75rem;
  color: #fff;
  text-align: center;
  font-size: 0.9375rem;
  border-radius: 5px;
}

.card_ok_sec {
  margin-top: 2.5rem;
  padding: 1.875rem;
  background: #f8f8f8;
  border-radius: 0.9375rem;
}
.card_ok_sec .top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px dashed #dbdbdb;
}
.card_ok_sec .top img {
  width: 3.125rem;
}
.card_ok_sec p {
  padding: 1.25rem 0;
  font-size: 1.125rem;
  font-weight: 500;
}
.card_ok_sec .bot {
  text-align: center;
}
.card_ok_sec .bot em {
  font-size: 1.375rem;
}
.card_ok_sec .bot em strong {
  font-size: 1.75rem;
}

/* 메일 탭 */
.popup_wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
}
div.pop_con {
  box-sizing: border-box;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  width: 500px;
  border-radius: 5px;
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  padding-bottom: 1.25rem;
}
div.pop_con table {
  table-layout: fixed;
}
div.tit {
  background-color: var(--bs-dark-point);
  color: #fff;
  padding: 0 0.625rem;
}
div.tit h4 {
  line-height: 1.875rem;
  color: #ffffff;
  font-size: 0.8125rem;
}

/* 메일 상단 조회*/
div.pop_inner {
  position: relative;
  padding: 1.25rem;
}
/* 추가 수정 19.10.28 */
div.text_box.background {
  background: #eeeeee;
  border-radius: 5px;
}
div.gray_box_01 > p {
  background: #eeeeee;
  border: 1px solid #e5e5e;
  border-radius: 5px;
  padding: 5px;
}
/* 끝 */
div.pop_inner div.gray_box_01 {
  position: absolute;
  margin: 0 auto;
  border-radius: 5px;
}
div.gray_box_01 select {
  display: inline-block;
  width: 12.5rem;
  padding: 2.5px 0.9375rem;
  border: 1px solid #aaa;
  border-radius: 5px;
}

/*   좌측에 출력이 있는 경우에만 P.mt0와 print 가 붙음*/
div.pop_inner h3.mt0,
p.mt0,
span.mt0 {
  font-size: 0.8125rem;
  line-height: 1.875rem;
  text-align: left;
  color: var(--bs-dark-point);
  font-weight: 600;
}
div.pop_inner h3.mt0:before,
p.mt0:before,
span.mt0:before {
  content: "";
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background-color: #e4007f;
}
/* 구매정보, 결제금액정보 의 간격조절 (2개로 나뉘는 경우)*/
div.pop_inner span.mt0 {
  margin-right: 8.125rem;
}
div.pop_inner span.mt0 + span.mt0 {
  position: relative;
  line-height: 0px;
  margin-left: 1.875rem;
}

/*  테이블 */
div.stats_tbl table {
  table-layout: fixed;
}
.bg-pink {
  background-color: #e4007f !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
}
.bg-navy {
  background-color: var(--bs-dark-point) !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
}
#orderNo {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-all;
  word-break: break-all;
}
div.stats_tbl {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 5px;
  margin-top: 5px;
}
div.stats_tbl th {
  background: #ededed;
  font-size: 0.8125rem;
  text-align: left;
  padding-left: 0.625rem;
  color: #3b3b3d;
  font-weight: 600;
  vertical-align: middle;
}
div.stats_tbl td {
  height: 1.875rem;
  vertical-align: middle;
  text-align: left;
  color: #898989;
  border-bottom: 1px solid #e5e5e5;
  font-size: 0.8125rem;
  padding-left: 0.625rem;
  color: #3b3b3d;
}
div.stats_tbl td.br04 {
  border-bottom: 0;
}
div.stats_tbl span.e4007f,
div.stats_tbl td.e4007f {
  color: #e4007f;
  font-weight: 600;
}
div.stats_tbl td.right {
  text-align: right;
}
.EN {
  padding-left: 5px !important;
}

/* 상세설명 작은글씨*/
div.text_box ul {
  margin-top: 5px;
}
div.text_box li {
  padding-left: 0.625rem;
  font-size: 0.75rem;
  text-align: left;
  color: #6e6e6e;
  line-height: 1.125rem;
}
div.text_box li:first-child:before {
  content: "";
  display: inline-block;
  margin-right: 5px;
  text-align: center;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background-color: #e4007f;
}
div.text_box span.e4007f {
  color: #e4007f;
  font-weight: 700;
}

/*  주의사항 박스 */
div.gray_box_02 {
  box-sizing: border-box;
  background: #ededed;
  border-radius: 5px;
  padding: 0.625rem;
}
div.gray_box_02 p {
  font-size: 0.8125rem;
  text-align: left;
  line-height: 1.5em;
}
div.gray_box_02 span.e4007f {
  color: #e4007f;
}

/*버튼(이메일 포함)*/
div.pop_btn {
  text-align: center;
}
div.pop_btn input,
div.gray_box_01 input {
  background: var(--bs-dark-point);
  display: inline-block;
  width: auto;
  border: none;
  color: #ffffff;
  font-size: 0.8125rem;
  height: 1.5625rem;
  box-sizing: border-box;
  line-height: 1.25rem;
  text-align: center;
  border-radius: 5px;
  -webkit-appearance: none;
  cursor: pointer;
  margin: 0 3px;
}
div.pop_btn input.gray,
div.gray_box_01 input.gray {
  background: #6e6e6e;
}
div.pop_btn input.email {
  background: #fff;
  color: #000;
  height: 1.875rem;
  width: 15.625rem;
  box-sizing: border-box;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  padding: 0 0.625rem;
  font-size: 1rem;
  transition: border-color 0.3s;
  vertical-align: middle;
  -webkit-appearance: none;
}
div.pop_btn input.white,
div.gray_box_01 input.white {
  background: #f2ccff;
  color: #4d4d4d;
  margin-left: 90px;
  margin-right: -4px;
}
div.pop_btn input.blue,
div.gray_box_01 input.blue {
  background: var(--bs-bg-point);
  color: #4d4d4d;
  margin-left: -3px;
}
input:disabled.white {
  background: #9900cc !important;
  color: #ffffff !important;
}
input:disabled.blue {
  background: var(--bs-deep-point) !important;
  color: #ffffff !important;
}
div.pop_btn input,
div.kren_box_01 input {
  background: var(--bs-dark-point);
  display: inline-block;
  border: none;
  color: #ffffff;
  font-size: 0.8125rem;
  height: 1.5625rem;
  box-sizing: border-box;
  line-height: 1.25rem;
  text-align: center;
  border-radius: 5px;
  -webkit-appearance: none;
  cursor: pointer;
}
div.pop_btn input.white,
div.kren_box_01 input.white {
  background: #f2ccff;
  color: #4d4d4d;
  margin-right: -3px;
  margin-bottom: 3px;
}
div.pop_btn input.blue,
div.kren_box_01 input.blue {
  background: var(--bs-bg-point);
  color: #4d4d4d;
  margin-left: -3px;
  margin-bottom: 3px;
}

div.cancel_view img {
  position: absolute;
  text-align: center;
  top: 400px;
  left: 122px;
}

/*스크롤바 css*/
/* ::-webkit-scrollbar { width: 5.2px; }
	::-webkit-scrollbar-track { display: none; }
	::-webkit-scrollbar-thumb { background-color:#EFADFA; }
	::-webkit-scrollbar-thumb:hover { background-color:#E670FB; }
	::-webkit-scrollbar-thumb:active { background-color:#E25AFA; }
	::-webkit-scrollbar-button { display: none; } */


  @media only screen and (max-width: 1280px) and (min-width: 768px)  {
    html{
      font-size: 14px;
    }
    :root{
      --nav-width:  5rem
    }
    #header .left .logo{
      width: 2.0385rem;
      height: .9231rem;
      background-image: url(../img/common/logo_symbol.png) ;
    }
 #nav .sub_menu{
  width: var(--nav-width);
  height: calc(var(--nav-width) - 1rem);
  position: relative;
  
}
#nav .sub_menu .sub_menu_item.open{
  display: none;
  position: absolute;
  left: 3.75rem;
  top: 0;
}
#nav .sub_menu .label{
   font-size: 0;
  transition: color 0.3s;
 }
 #nav .sub_menu .label i {
  display: none;
 }
 #nav .sub_menu:hover .label{
  font-size: 1rem;
  padding-left: 4.75rem;
  background-color: #172850;
  color: #e0e4e9;
 }
 #nav .sub_menu:hover{
   overflow: visible;
   width: 240px;
   height: calc(var(--nav-width) - 1rem);  font-size: inherit;
   transition: 0;
   position: relative;
   z-index: 546874;
background-color: #22345e;
  }
  #nav .sub_menu:hover .sub_menu_item{
    display: block;
    position: relative;
width: calc(240px - 3.75rem);    
background-color: #22345e;
  }
  #nav .sub_menu .sub_menu_item a.dep_2{
    padding-left: 1rem;
  }
  }



/*************************/
/*  매출전표 팝업 CSS    */
/*************************/

.popupArea_ {
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    position: fixed;
    /*display: none;*/
    justify-content: center;
    align-items: center;
    background-color: rgba(1, 1, 1, 0.25);
    z-index: 999999;
    word-break: keep-all;
    word-wrap: break-word;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    font-family: Pretendard !important;
}

.popupArea_.show_ {
    display: flex;
}

.popupContent_ {
    background-color: #FFF;
    width: 352px;
    padding: 20px 16px 0;
    max-height: 825px;
    height: 90%;
    overflow: auto;
    border-radius: 10px;
    margin-left: calc(50% - 95px);
    margin-top: 5%;
}

.popupContent_::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
}

.popupContent_::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    width: 4px;
}

.header_ {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin: 0px 0px 16px;
    padding: 0px;
    width: 100%;
    border-radius: 0px;
}

.close_ {
    cursor: pointer;
}

.top_ {
    width: 100%;
    display: flex;
    background: #008bff;
    padding: 4px 0;
}

.topBtn_ {
    font-size: 14px;
    font-weight: 400;
    color: rgb(251, 252, 255);
    margin: 0px;
    text-align: left;
    white-space: nowrap;
    text-decoration-line: none;
    text-underline-offset: 2px;
    background: none;
    margin: 0px;
    padding: 0px 10px;
    width: 100%;
    height: 30px;
    border-radius: 0px;
}

.title_ {
    width: 100%;
    height: 26px;
    border: 1px solid #ebeef2;
    background-color: #f4f6f9;
    margin-top: 6.5px;
    margin-bottom: 6.5px;
    display: flex;
    align-items: center;
    padding-left: 10px;

    font-size: 11px;
    font-weight: 400;
    color: rgb(44, 58, 75);
    text-align: left;
    white-space: nowrap;
    text-decoration-line: none;
    text-underline-offset: 2px;
}

.content_ {
    min-height: 21px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 0 10px;
    letter-spacing: 0px;
    font-variant-numeric: tabular-nums;
}

.content_ .start_ {
    font-size: 11px;
    font-weight: 400;
    color: rgb(57, 68, 82);
    margin: 0px;
    text-align: left;
    white-space: nowrap;
    text-decoration-line: none;
    text-underline-offset: 2px;
    line-height: 140%;
    background: none;
}

.content_ .end_ {
    font-size: 11px;
    font-weight: 400;
    color: rgb(57, 68, 82);
    margin: 0px;
    white-space: nowrap;
    text-decoration-line: none;
    text-underline-offset: 2px;
    line-height: 140%;
    background: none;
    text-align: right;
}

.content_ .end_.red_ {
    font-size: 13px;
    font-weight: 500;
    color: rgb(239, 68, 82);
    margin: 0px;
    text-align: left;
    white-space: nowrap;
    text-decoration-line: none;
    text-underline-offset: 2px;
    line-height: 140%;
    background: none;
}

.end_page_ {
    border-top: 1px solid rgb(235, 238, 242);
    margin: 8.5px 0px 0px;
    padding: 16px 0px 30px;
    width: 100%;
    border-radius: 0px;
}

.end_page_ p {
    font-size: 10px;
    font-weight: 400;
    color: rgb(84, 93, 105);
    text-align: left;
    white-space: normal;
    margin: 0px;
    letter-spacing: 0px;
    text-decoration-line: none;
    text-underline-offset: 2px;
    line-height: 12px;
    background: none;
}

/*************************/