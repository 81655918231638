.context-menu {
    position: fixed; 
    background: white;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    padding: 8px 0;
    border-radius: 5px;
    width: 120px;
}

.context-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.context-menu ul li {
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    text-align: center;
}

.context-menu ul li:hover {
    background: #f0f0f0;
}
