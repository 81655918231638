@charset "utf-8";

/* 노토산스 */
@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 350;
    src: url(./fonts/notokr-demilight.eot);
    src: local('notokr-demilight'),
        url(./fonts/notokr-demilight.eot?#iefix) format('embedded-opentype'),
        url(./fonts/notokr-demilight.woff) format('woff'),
        url(./fonts/notokr-demilight.woff2) format('woff2'),
        url(./fonts/notokr-demilight.ttf) format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 400;
    src: url(./fonts/notokr-regular.eot);
    src: local('notokr-regular'),
        url(./fonts/notokr-regular.eot?#iefix) format('embedded-opentype'),
        url(./fonts/notokr-regular.woff) format('woff'),
        url(./fonts/notokr-regular.woff2) format('woff2'),
        url(./fonts/notokr-regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 500;
    src: url(./fonts/notokr-medium.eot);
    src: local('notokr-medium'),
        url(./fonts/notokr-medium.eot?#iefix) format('embedded-opentype'),
        url(./fonts/notokr-medium.woff) format('woff'),
        url(./fonts/notokr-medium.woff2) format('woff2'),
        url(./fonts/notokr-medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    src: url(./fonts/notokr-bold.eot);
    src: local('notokr-bold'),
        url(./fonts/notokr-bold.eot?#iefix) format('embedded-opentype'),
        url(./fonts/notokr-bold.woff) format('woff'),
        url(./fonts/notokr-bold.woff2) format('woff2'),
        url(./fonts/notokr-bold.ttf) format('truetype');
}


/* 나눔스퀘어 */
@font-face {
    font-family: 'NanumSquare';
    font-weight: 300;
    src: url(./fonts/NanumSquareL.woff) format('woff');
    
}
@font-face {
    font-family: 'NanumSquare';
    font-weight: 400;
    src: url(./fonts/NanumSquareR.woff) format('woff');
    
}

@font-face {
    font-family: 'NanumSquare';
    font-weight: 700;
    src: url(./fonts/NanumSquareB.woff) format('woff');
    
}

@font-face {
    font-family: 'NanumSquare';
    font-weight: 800;
    src: url(./fonts/NanumSquareEB.woff) format('woff');
    
}

/* 스포카 한 산스 */

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 100;
    src: url(./fonts/SpoqaHanSansNeo-Thin.woff) format('woff');
    
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 300;
    src: url(./fonts/SpoqaHanSansNeo-Light.woff) format('woff');
    
}

@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 400;
    src: url(./fonts/SpoqaHanSansNeo-Regular.woff) format('woff');
    
}
@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 500;
    src: url(./fonts/SpoqaHanSansNeo-Medium.woff) format('woff');
    
}
@font-face {
    font-family: 'Spoqa Han Sans Neo';
    font-weight: 700;
    src: url(./fonts/SpoqaHanSansNeo-Bold.woff) format('woff');
}





html{overflow-x:hidden;} 
body { 
	width:100%; height:100%; 
	letter-spacing:-0.04em; 
	color:#252525; 
	font-size:16px;  
	font-family: 'Spoqa Han Sans Neo' , 'Noto Sans KR', sans-serif, 'NanumSquare';
	background-color:#fff;
	word-break: keep-all;
	line-height: 1;
}



/** * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/) * http://cssreset.com */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
	box-sizing:border-box;
    letter-spacing: -0.04em;
    /*세로모드에서 가로모드로 전환할때 텍스트가 약간 커지는 현상 방지*/
	margin:0; padding:0;border:0;vertical-align:baseline;-webkit-text-size-adjust:none;
  	text-decoration:none;
	-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block; 
    font-family:'Spoqa Han Sans Neo', 'Noto Sans KR', sans-serif;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
	box-sizing:border-box;
}
blockquote:before, blockquote:after,q:before, q:after {
	content: '';
	content: none;
	box-sizing:border-box;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button, a {
  padding:0; margin:0; background:transparent; border:0; outline:0; cursor:pointer; font-size:inherit; font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', sans-serif;
  color: #252525;
}
button {
    border: 0 none;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
    font-family: 'Spoqa Han Sans Neo' , 'Noto Sans KR', sans-serif;
}


textarea, input, select, textarea {
	padding:0; margin:0; outline:0; font-size:inherit; font-family: 'Spoqa Han Sans Neo', 'Noto Sans KR', sans-serif; border:1px solid #dbdbdb;
	-webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; width:100%; padding:0 10px;
   
}

select {
    background: url(../img/common/filter_sel.png) right 10px center no-repeat;
}
input[type="text"] {
    padding: 0 10px;
    border: 1px solid #dbdbdb;
}
input[type="radio"], input[type="checkbox"] {
	-webkit-appearance: none; -moz-appearance: none; appearance: none;
}

input[type=date]::-webkit-inner-spin-button, .top_wrap .input_wrap input[type=date]::-webkit-outer-spin-button {
	-webkit-appearance: none; -moz-appearance: none; appearance: none;
}

select {
	cursor:pointer; -webkit-appearance: none; -moz-appearance: none; appearance: none;
}

input, select, textarea {
	-webkit-appearance: none;
	-webkit-border-radius: 0;
	box-shadow: none;
}

address, em, i {
    font-style: normal;
}

img {
    max-width: 100%;
}
textarea {
    border-radius: 4px;
    resize: none;
}

/* placeholder */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */ color: #999999; }
::-moz-placeholder { /* Firefox 19+ */ color: #999999; }
:-ms-input-placeholder { /* IE 10+ */ color: #999999; }
:-moz-placeholder { /* Firefox 18- */ color: #999999; }

/* 스크롤 */
/* ::-webkit-scrollbar-track { background-color: #f5f5f5; }
::-webkit-scrollbar { width: 0px;
                      background: transparent;
                      height: 0px !important; }
::-webkit-scrollbar-thumb { background-color: #aaa; } */





/* 데이트피커  */
/* datepicker */
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all{ display:none; position:relative; margin-top:10px; background:#fff; border:solid 1px #dddddd; border-radius:5px; z-index:99999 !important; }
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all:before {position:absolute; top:-1px; left:20px; content:""; width:8px; height:8px; border-top:solid 1px #ddd; border-radius:0px; border-left:solid 1px #ddd; background-color:#fff; transform:translate(-50%,-50%) rotate(45deg);}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all table{width: auto; background:#fff;}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all table thead tr th{width:32px; line-height:24px; height:24px;
text-align:center; padding-right:5px;}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all table thead tr th span{font-size:10.1px; font-weight:300; color:#999999;}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all table tbody tr td{text-align:center; color:#666666; font-size:12px;
width:35px; line-height:35px; height:35px; padding-right:0px;  background:#ffffff;}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all table tbody tr td a{ display:block; margin:1px; border-radius:50%;  color:#666666; font-size:12px; font-weight:300;     line-height: 35px;}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all table tbody tr td a:hover { background-color:#cc0000; color:#fff;}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all table tbody tr td a.ui-state-default.ui-state-active{ border:solid 1px #cc0000;}
.ui-datepicker-title{display:table; margin:0 auto; margin-top:20px; margin-bottom:20px; }
.ui-datepicker-title span{text-align:center; color:#000000; font-size:15px; font-weight:500;}
.ui-datepicker-title span.ui-datepicker-year { font-weight:300;}
.ui-datepicker-header.ui-widget-header.ui-helper-clearfix.ui-corner-all{position:relative;}
.ui-datepicker-header.ui-widget-header.ui-helper-clearfix.ui-corner-all a.ui-datepicker-prev{text-indent:-9999px; position:absolute; 
background:url("../img/btn/calandar_left.png")no-repeat center; width:16px; height:22px; left:10px;top:6px;}
.ui-datepicker-header.ui-widget-header.ui-helper-clearfix.ui-corner-all a.ui-datepicker-next{text-indent:-9999px; position:absolute; 
background:url("../img/btn/calandar_right.png")no-repeat center; width:16px; height:22px; right:10px;top:6px;}
.ui-datepicker.ui-widget.ui-widget-content.ui-helper-clearfix.ui-corner-all table tbody tr td.ui-datepicker-unselectable.ui-state-disabled
{background:#fff; color:#cecece;}
input.date_form :-ms-input-placeholder { color: #333333; } 
input.date_form::-webkit-input-placeholder { color: #333333; }
input.date_form::-moz-placeholder { color: #333333; }
button.ui-datepicker-trigger {display:none;}
img.ui-datepicker-taigger { margin-left:10px; margin-top:1px;}
.date_form { background:url("../img/ico/ico_calander.png") no-repeat; background-position: 10px center;}
.date_form.date_form3 { background:none;}
.datepick_box {
    display:flex;
    align-items: center;
}
.datepick_box .datepick {
    position:relative;
} 
.datepick_box span {
    display: block;
    width: 20px;
    font-size:15px;
    color:#999999;
    text-align: center;
}
.datepick input + img {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
.datepick input {
    width:130px;
    line-height: 30px;
    font-size:15px;
    color:#252525;
    letter-spacing: -0.05em;
}
.datepick input::placeholder {
    color: #999;
}