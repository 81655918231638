.btn{
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: inherit;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.5rem 0.9rem;
    font-size: 0.9375rem;
    border-radius: 0.15rem;
}

.btn.btn-sm{
    font-size: 0.875rem;
    height: 32px;
    line-height: 30px;  padding: 0 16px;
}
.btn.btn-xxl{
    font-size: 18px;
    width: 160px;
    height: 55px;
}
.rounded-pill{
    border-radius: 50px;
}

.defaultBtn {
    display: inline-block;
    padding: 0 20px;
    border: 1px solid #dbdbdb;
    border-radius: 50px;
    line-height: 30px;
    color: #999999;
  }
/* button-menu-mobile */

.button-menu-mobile {
    border: none;
    color: #313a46;
    height: var(--mo-header-height);
    line-height: var(--mo-header-height);
    width: var(--mo-header-height);
    background-color: transparent;
    font-size: 1.7857rem;
    cursor: pointer;
    float: left;
    z-index: 1;
    position: relative;
    flex-shrink: 0;
    display: none;
  }

.button-menu-mobile .lines {
    width: 18px;
    display: block;
    position: relative;
    height: 16px;
    transition: all 0.5s ease;
    margin-top: 26px;
    margin-left: 10px;
  }
  .button-menu-mobile span {
    height: 2px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: block;
    margin-bottom: 5px;
    transition: transform 0.5s ease;
  }
  .button-menu-mobile span:nth-of-type(2) {
    width: 24px;
  }
  .button-menu-mobile.disable-btn {
    display: none;
  }
  

  
@media screen and (max-width: 767.98px) {
    .button-menu-mobile {
        display: block;
    }

    .btn{
        display: inline-block;
        font-weight: 400;
        line-height: 1.5;
        color: #6c757d;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        padding: 0.5rem 0.9rem;
        font-size: 0.875rem;
        border-radius: 0.15rem;
    }
    .btn.btn-sm{
        font-size: .8571rem;
        height: 2.1429rem;
        line-height: 2.0714rem;  padding: 0 .7143rem;
    }
    .btn.btn-xxl{
        border-radius: 1.7857rem;
        font-size: 1.0714rem;
        width: 7.1429rem;
        height: 2.8571rem;
        
    }
    .rounded-pill{
        border-radius: 3.5714rem;
    }
    
    .defaultBtn {
        display: inline-block;
        padding: 0 20px;
        border: 1px solid #dbdbdb;
        border-radius: 50px;
        line-height: 30px;
        color: #999999;
      }
    /* button-menu-mobile */
    
}
/* button-menu-mobile */
